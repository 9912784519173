import { Box, Button, Container, Grid, Paper } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import BarcodeList, { BarcodeOriginal } from '../components/Reactbarcode'
import CenterModal from '../components/Modal';
import { toast } from 'react-toastify';
import apiClient from '../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import Paginate from '../components/mui/Paginate';
import {Helmet} from "react-helmet";
import jsPDF from 'jspdf';  
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import html2pdf  from 'html2pdf.js';
import TabBars from '../components/mui/TabBars';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import SelectOption from '../components/mui/SelectOption';
import LoadingOverlay from '../components/mui/LoadingOverlay';
import { BarcodeFilterSidebar } from '../components/sidebar-filter/ProductFilterSidebar';
import { objectToQueryString } from '../modules/helpers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BarcodeListArchive from '../components/BarcodeArchive';


let limit=12
export default function   ArchiveBarcode() {

  return (
    <div>
<div className='d-flex justify-content-center'>

    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
 
  </Box>
</div>

    <ArchiveTab/>

    </div>
  )
}

function ArchiveTab() {
    const [count, setCount] = useState(0);
    const [load, setLoad]= useState(false);
    const[barcodeValues,setBarcodeValues]=useState()
    const [barcodeType,setType]=useState("")
    const [showFilter, setShowFilter] = useState(false);
    const [date, setDate] = useState([]);
    const [reset, setReset] = useState(false);
    const intialFilters={
      barcode:"",
      activated:""
    }
    const [filters,setFilters]=useState(intialFilters)
    const onReset=()=>{
      setFilters(intialFilters)
     setReset(true)
    }
   const onFilterChange=(key,value)=>{
      setFilters({...filters,[key]:value})
    }
    useEffect(()=>{
      if(reset)
      fetchdata()
    },[reset])
    useEffect(() => {
      fetchdata()
    }, [barcodeType])

    const fetchdata=async(page=1)=>{
 
      if(page==1&&count)
      setCount(0)
      setLoad(true);
      const result=await apiClient.get(`/qr/?limit=${limit}&page=${page}&archive=true&key=${barcodeType}&${objectToQueryString(filters)}`);
      setLoad(false);
      if(!result.ok) return toast.error("Error");
      if (!count||page==1) setCount(result.data.count);
      //console.log(result.data,"lllllll");
       setBarcodeValues(result.data.results);
    }
 const handlePageChange = (event, value) => {
      fetchdata(value);
    };

    const baseUrl = "www.gphtest.com";

    return (
      <div >
        <Helmet>
          <title>
            Barcode
          </title>
        </Helmet>
        <Container className='' maxWidth='lg'>
        <BarcodeFilterSidebar
        openFilter={showFilter}
        onOpenFilter={() => setShowFilter(true)}
        onCloseFilter={() => setShowFilter(false)}
        handleChange={onFilterChange}
        values={filters}
        onReset={onReset}
        fetchData={fetchdata}
      />
      {load?(
        <Box display="flex" justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
      ): 
        (<div className='d-flex justify-content-center '>
          
       <Grid  md={9} xs={12}  container  className='shadow' sx={{marginTop:5, borderRadius:10}}  >
       <Grid className='p-3 row justify-content-end' item xs={12}>

<div className='col-4'>
<SelectOption style={{width:"100%"}} size="small"  
data={
[{value:"!pp",label:"Normal"},
{value:"pp",label:"PP Technician"},
{value:"exp",label:"EXP Technician"}
]}  id="outlined-basic" value={barcodeType} 
onChange={(e) => {
  setType(e.target.value);
  console.log(e.target.value);
}}
 label="Barcode Type" variant="outlined" />
</div>

 

      </Grid>
  
        {barcodeValues?.map(( value)=>(
      
     
         <Grid className='' item md={4} sm={6} xs={12} spacing={5} sx={{ marginTop: 3, padding: 2 }}>
           <BarcodeListArchive  url={baseUrl} value={value} ShowiIcon={true}  />
         </Grid>
       
         ))}
     
            
        </Grid>
      </div>)}
    <div className={`${load&&"d-none "}   d-flex justify-content-center `} >
   <Paginate
     className="btn_bg" color="primary" style={{ color: "white",  }} count={count} limit={limit} onChange={handlePageChange}/>
    </div>  
  
        </Container>
      </div>
    )
  }

