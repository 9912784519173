import * as React from 'react';
import PropTypes from 'prop-types';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {   Chip, CircularProgress, Paper,  } from '@mui/material'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import BarcodeList from './Reactbarcode';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from 'react';
import useApi from '../hooks/useApi';
import apiClient from '../api/apiClient';
import { useEffect } from 'react';
import Paginate from './mui/Paginate';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../redux/modalSlice';
import { objectToQueryString } from '../modules/helpers';
import ShopFilterSidebar, { SampleShopFilterSidebar } from './sidebar-filter/ProductFilterSidebar';
import { removeReportData } from '../redux/reportSlice';
import MuiDialog from './mui/MuiDialog';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const limit=10

export default function ClientSampleTabs() {
    const initial = {
    };
    const navigate = useNavigate();
    const {type}=useOutletContext()
    const { id } = useParams();
      const [value, setValue] =useState(0);
      const [show, setShowModal]=useState(false)
      const [ShowAssign, setShowAssign]=useState(false)
      const [technician, setTechnician] = useState(initial);
      const [count, setCount] = useState(0);
      const [showFilter, setShowFilter] = useState(false);
    const [date, setDate] = useState([]);
    const intialFilters={
      start_date:  "",
      end_date:"",
      date_search_type:"client",
      keyword:"barcode",
      search:""
    }
    const [filters,setFilters]=useState(intialFilters)
    const onReset=()=>{
      setFilters(intialFilters)
      setDate([])
      fetchData()
    }
   const onFilterChange=(key,value)=>{
      setFilters({...filters,[key]:value})
    }
      const {request,error,data,loading}=useApi((route)=>apiClient.get(route))
   //console.log(data,"fghu data");
      const fetchData = async (page=1) => {
        const filterObject={
limit,
page,
          start_date:date?.[0]||"",
        end_date:date?.[1]||"",
        date_search_type:filters.date_search_type,
        [filters.keyword]:filters.search
        }
        if(page==1&&count)
    setCount(0)
        const result = await request(`/sample/?sampleStatus=${status}&${objectToQueryString(filterObject)}`);
        if (!count||page==1) setCount(result.data.count);
        console.log(count, "count result")
      };
  
  
      const handlePageChange = (event, value) => {
        fetchData(value);
      };
  const [status,setStatus]=useState("REC")
  useEffect(() => {
  fetchData()
  }, [status]);

  const handleDetailsClick = (id) => {
    //console.log(id,"ddddddd")
    navigate(`/tn/pet-bio/${id}`);
  };
  const reportDataOld=useSelector(state=>state.report.report)
  const dispatch=useDispatch()
 const handleReportGenerate=(barcode,client)=>{
  if(reportDataOld&&reportDataOld.barcode!==barcode){
    return     dispatch(showModal({
      error:true,
      title: 'Report',
      message: "Please finish report already in progress then you can generate another."
    }));
  }
  else
  navigate(`/tn/report/${barcode}?client=${client}` )
 }
    return (
      <>
        <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
   
      <CurrrentlyGeneratingReport/>
      </Grid>
      </Grid>
      <Box className=' d-flex justify-content-center' >


     <Grid className='' container md={10} xs={12} spacing={2} component={Paper} elevation={5} sx={{ marginTop: 5, padding:5, borderRadius:7 }}>
       <div className='d-flex gap-2'>
            <Button
          variant={value === 0 ? 'contained' : 'outlined'}
          onClick={()=>{
          setValue(0)
          setStatus("REC")
        }
        }
        sx={{
          backgroundColor: value === 0 ? '#3E4755' : 'none',
          color: value === 0 ? '#FFFFFF' : 'inherit',
          outline: 'none', // Remove the outline on focus
          '&:hover': {
            backgroundColor: value === 0 ? '#3E4755' : 'none', // Set the hover background color only when active
          },
          '&:active': {
            backgroundColor: '#3E4755', // Set the active background color
          },
          border: value === 0 ? 'none' : '1px solid #716879',
          borderColor: value === 0 ? 'none' : 'secondary.main',
        }}
      >
        Received Samples
      </Button>


      <Button
        variant={value === 1 ? 'contained' : 'outlined'}
        onClick={()=>{
          setValue(1)
          setStatus("SUB")}}
        sx={{
          backgroundColor: value === 1 ? '#3E4755' : 'none',
          color: value === 1 ? '#FFFFFF' : 'inherit',
          outline: 'none', // Remove the outline on focus
          '&:hover': {
            backgroundColor: value === 1 ? '#3E4755' : 'none', // Set the hover background color only when active
          },
          '&:active': {
            backgroundColor: '#3E4755', // Set the active background color
          },
          border: value === 1 ? 'none' : '1px solid',
          borderColor: value === 1 ? 'none' : 'secondary.main',
        }}
      >
        Submitted Samples
      </Button>

       </div>
       <Box className='w-100 d-flex justify-content-end'>
                      <Button sx={{borderRadius:20,}} onClick={()=>setShowModal(true)} className='m-2 btn_bg_secondery' variant="contained" color="primary"  >
                      Receive New Sample
                      </Button>
                      <NewSampelModal
                            fetchData={fetchData}
                                show={show}
                                onHide={()=>setShowModal(false)}
                                  />
                  </Box>
                  <SampleShopFilterSidebar
        openFilter={showFilter}
        onOpenFilter={() => setShowFilter(true)}
        onCloseFilter={() => setShowFilter(false)}
        handleChange={onFilterChange}
        values={filters}
        onReset={onReset}
        fetchData={fetchData}
        date={date}
        setDate={setDate}
      />
      <Grid container>
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
              <Grid className='' >
              {!loading?<>
                {data?.map(({client={},_id}, index) => (
                  <div className=' row justify-content-center d-flex gy-2 gx-2 flex-wrap px-4 mb-5'
                  
                  style={{    backgroundColor: '#F1F1F5',
                  // color:"white",            
                  borderRadius: 15,
                  alignItems: 'center',
                  paddingTop:40,
                  paddingBottom:40,
                  }}
                  >
                      <div className='col-md-9 d-flex gap-3 flex-wrap'
         
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <AccountCircleIcon fontSize='large' />
                        <Box sx={{}}>
                        <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                            Name:
                          </Typography>
                          <Typography className='fs_10px' sx={{fontWeight:'bold'}}>
                            {client?.firstname+ " " + client?.lastname}
                            </Typography>
                          {/* <Typography variant="body2" sx={{ fontSize: '0.6rem', fontWeight:'bold' }}>
                            {client?.email}
                          </Typography> */}
                          <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                            Address:
                          </Typography>
                          <Typography className='fs_10px'>
                          {client?.address}
                          </Typography>
                        </Box>
                        <Box sx={{}}>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                            Email
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.email}
                          </Typography>

                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                          Contact
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.phone_no}
                          </Typography>
                        </Box>
                        <Box sx={{}}>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                            Pet Name
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.pet?.pet_name}
                          </Typography>

                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                          Barcode
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.barcode}
                          </Typography>
                        </Box>
                        {/* <Box sx={{ width: '150px', height: '150px', overflow: 'hidden' }}>
                              <BarcodeList key={index} value={0} />
                          </Box> */}


                        
                        

                      </div>
                        <div className="col-md-3 d-flex flex-column flex-sm-row">

                            <Box className=''>
                              <div>
                            
                            <Button
                              onClick={() => handleDetailsClick(_id)}
                            className=' m-2 btn_bg_secondery fs_10px same-size-button' variant="contained" color="primary"  >
                                Details
                            </Button>
                              
                              </div>
                            <div>

                            <Button

                            onClick={()=>handleReportGenerate(client.barcode,client._id)}
                            className='m-2 btn_bg_secondery fs_10px same-size-button white_space' variant="outlined" color="primary" >
                            GENERATE REPORT
                            </Button>
                            </div>
                            </Box>
                        </div>
                    </div>
            ))} </>:<Box sx={{ display: 'flex' ,justifyContent:"center"}}>
                    <CircularProgress />
                    </Box>}
          <div className={`${loading&&"d-none"}`}>
        <Paginate color="primary" style={{ color: "white" }} count={count} limit={limit} onChange={handlePageChange}/>
          </div>

              <AssignModal
                  show= {ShowAssign}
                  onHide={()=>{setShowAssign(false)}}
                  />

                    {/* <NewSampelModal
                        show={show}
                        onHide={()=>setShowModal(false)}
                          /> */}
              </Grid>
          
              </TabPanel>
            </Grid>
      </Grid>

      <Grid container>
            <Grid item xs={12}>

              <TabPanel value={value} index={1}>
               
                {!loading?data?.map(({client={},_id}, index) => (
             <div className=' row justify-content-center d-flex gy-2 gx-2 flex-wrap px-4 mb-5'
                  
             style={{    backgroundColor: '#F1F1F5',
             // color:"white",            
             borderRadius: 15,
             alignItems: 'center',
             paddingTop:40,
             paddingBottom:40,
             minHeight:"200px"
             }}
             >
                 <div className='col-md-9 d-flex gap-3 flex-wrap'
    
                   style={{
                     display: 'flex',
                     alignItems: 'center'
                   }}
                 >
                   <AccountCircleIcon fontSize='large' />
                   <Box sx={{}}>
                   <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                       Name:
                     </Typography>
                     <Typography className='fs_10px' sx={{fontWeight:'bold'}}>
                       {client?.firstname+ " " + client?.lastname}
                       </Typography>
                     {/* <Typography variant="body2" sx={{ fontSize: '0.6rem', fontWeight:'bold' }}>
                       {client?.email}
                     </Typography> */}
                     <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                       Address:
                     </Typography>
                     <Typography className='fs_10px'>
                     {client?.address}
                     </Typography>
                   </Box>
                   <Box sx={{}}>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                       Email
                     </Typography>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                       {client?.email}
                     </Typography>

                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                     Contact
                     </Typography>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                       {client?.phone_no}
                     </Typography>
                   </Box>
                   <Box sx={{}}>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                       Pet Name
                     </Typography>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                       {client?.pet?.pet_name}
                     </Typography>

                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                     Barcode
                     </Typography>
                     <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                       {client?.barcode}
                     </Typography>
                   </Box>
                   {/* <Box sx={{ width: '150px', height: '150px', overflow: 'hidden' }}>
                         <BarcodeList key={index} value={0} />
                     </Box> */}


                   
                   

                 </div>
                   <div className="col-md-3 d-flex flex-column flex-sm-row">

                       <Box className=''>
                         <div>
                       
                         <Button sx={{}}  className=' m-2 btn btn_bg_secondery' variant="contained"
                            onClick={() => handleDetailsClick(_id)}
                            color='primary'>
                                Details
                            </Button>
                         
                         </div>
          
                       </Box>
                   </div>
               </div>
            ))
            : <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
                  <CircularProgress />
                  </Box>
            }
                <div className={`${loading&&"d-none"}`}>
        <Paginate color="primary" style={{ color: "white" }} count={count} limit={limit} onChange={handlePageChange}/>
          </div>
           
              </TabPanel>
        </Grid>
</Grid>
      </Grid>
       
      </Box>
      </>
    );
  }






  function AssignModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className=' ' closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
          <HelpIcon style={{ fontSize: '40px', color: '#FB8B75' }} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-center'>Assign this Sample</h4>
          <p className='text-center'>
          Assign this sample to a specific <br />
                  technician
          </p>
          <div className='d-flex justify-content-center'>
          <div className='col-md-8'>
          <label htmlFor="barcode">Technicians</label>
          <input type="text" name="" id="barcode" className="form-control" />
          </div>
          </div>
  
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button variant='contained' className='btn_bg px-5'>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function NewSampelModal(props) {
    let initialstate={
      // sampleStatus:"",
      barcode:"",
    }
    const dispatch=useDispatch()
    const [sample ,setSample]=useState(initialstate)
    const [load ,setLoad]=useState(false)
    const handleChange=(key,value)=>{
      setSample({...sample,[key]:value})
    }
    const handleSubmit=async(e)=>{
      e.preventDefault()
      setLoad(true)
      const result=await apiClient.post("/sample/",sample);
      setLoad(false)
      props.onHide();
      if(!result.ok)  return     dispatch(showModal({
        error:true,
        title: 'Sample',
        message: result.data.message,
      }));
      dispatch(showModal({
        title: 'Sample',
        message: 'Successfully Sample Received',
      }));
      setLoad(false)
      props.fetchData()
      props.onHide()
    }
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className=' ' closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
          <HelpIcon style={{ fontSize: '55px', color: '#7C828C' }} />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h4 className='text-center'>Receive New Sample</h4>
          <p className='text-center'>
          Please enter the code to receive the sample. An <br />
          email will be sent to the user stating that your sample <br />
          has been received.
          </p>
          <div className='d-flex justify-content-center'>
          <div className='col-md-8'>
          <label htmlFor="barcode">Code</label>
          <input type="text" name=""  id="barcode" className="form-control" value={sample.barcode} onChange={(e)=>handleChange("barcode",e.target.value)} />
          </div>
          </div>
  
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          {!load?<Button type='submit' variant='contained' className='btn_bg_secondery px-5'>Save</Button>:
           <Box sx={{ display: 'flex',justifyContent:"center" }}>
           <CircularProgress />
         </Box>}
        </Modal.Footer>
        </form>
      </Modal>
    );
  }
  

  function CurrrentlyGeneratingReport(){
    const [show,setShow]=useState(false)
    const dispatch=useDispatch()
   const reportDataOld=useSelector(state=>state.report.report)
const client=reportDataOld?.clData
console.log(client)
if(!client)
return
    return (<>
    
    <MuiDialog 
            
            // title={"Logout"}
            title={"Are you Sure want to discard current report?"} 
            show={show}
            Buttons={
              ()=>(<>
              
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button onClick={()=>dispatch(removeReportData())} color="error" variant="contained">
                  Confirm
                </Button>
              </>
              )
            }
            />
      <div className=' row justify-content-center d-flex gy-2 gx-2 flex-wrap px-4 mb-5'
                  
                  style={{    backgroundColor: '#F1F1F5',
                  // color:"white",            
                  borderRadius: 15,
                  alignItems: 'center',
                  paddingTop:40,
                  paddingBottom:40,
                  }}
                  >
                    <div className='d-flex col-12 gap-3'>
                      <h4>Report in Progress</h4>
                      <Chip label="working" color="info" />
                      </div>
                      <div className='col-md-9 d-flex gap-3 flex-wrap'
         
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <AccountCircleIcon fontSize='large' />
                        <Box sx={{}}>
                        <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                            Name:
                          </Typography>
                          <Typography className='fs_10px' sx={{fontWeight:'bold'}}>
                            {client.firstname+ " " + client.lastname}
                            </Typography>
                          {/* <Typography variant="body2" sx={{ fontSize: '0.6rem', fontWeight:'bold' }}>
                            {client.email}
                          </Typography> */}
                          <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                            Address:
                          </Typography>
                          <Typography className='fs_10px'>
                          {client.address}
                          </Typography>
                        </Box>
                        <Box sx={{}}>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                            Email
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client.email}
                          </Typography>

                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                          Contact
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client.phone_no}
                          </Typography>
                        </Box>
                        <Box sx={{}}>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                            Pet Name
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client.pet?.pet_name}
                          </Typography>

                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                          Barcode
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client.barcode?.key}
                          </Typography>
                        </Box>
                        {/* <Box sx={{ width: '150px', height: '150px', overflow: 'hidden' }}>
                              <BarcodeList key={index} value={0} />
                          </Box> */}


                        
                        

                      </div>
                        <div className="col-md-3 d-flex flex-column flex-sm-row">

                            <Box className=''>
                        
                            <div>

                            <Link to={`/tn/report/${client.barcode?.key}?client=${client._id}` } >
                            <Button
                            className='m-2 btn_bg_secondery fs_10px same-size-button white_space' variant="contained" color="primary" >
                           Continue
                            </Button>
                            </Link>
                            </div>
                            <div>
                            
                         
                            <Button
                            onClick={()=>setShow(true)}
                           className='m-2  btn fs_10px same-size-button white_space' variant="contained" color="error" >
                          Discard
                           </Button>
                             </div>
                            </Box>
                        </div>
                    </div>
    </>
    )
  }