import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    show: false,
    title: '',
    icon: null,
    onBack:()=>{},
    message: ''
  },
  reducers: {
    showModal: (state, action) => {
      const { title, icon, message,error,onBack } = action.payload;
      state.show = true;
      state.error = error;
      state.title = title;
      state.icon = icon;
      state.message = message;
      state.onBack=onBack
    },
    hideModal: state => {
      state.show = false;
      state.onBack&&state.onBack()
    },
  },
});

export const { showModal, hideModal } = modalSlice.actions;

// export const selectModal = state => state.modal;

export default modalSlice.reducer;
