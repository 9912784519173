import * as React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {   CircularProgress, Paper,  } from '@mui/material'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, AppBar, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { useOutletContext } from 'react-router-dom/dist';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Paginate from '../../components/mui/Paginate';
import apiClient from '../../api/apiClient';
import useApi from '../../hooks/useApi';
import { formatDate, objectToQueryString } from '../../modules/helpers';
import ShopFilterSidebar from '../../components/sidebar-filter/ProductFilterSidebar';
import LoadingOverlay from '../../components/mui/LoadingOverlay';
import downloadFile from '../../modules/downloadFile';

const ClientSubmittedReport = () => {
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const [date, setDate] = useState([]);
    const [reset, setReset] = useState(false);

    const intialFilters={
      start_date:  "",
      end_date:"",
      date_search_type:"client",
      keyword:"barcode",
      search:""
    }
    const [filters,setFilters]=useState(intialFilters)
    const onReset=()=>{
      setFilters(intialFilters)
      setDate([])
      setReset(true)
    }
   const onFilterChange=(key,value)=>{
      setFilters({...filters,[key]:value})
    }
    useEffect(()=>{
      if(reset)
      fetchData()
    },[reset])
    const {request,error,data,loading}=useApi((route)=>apiClient.get(route))
    console.log(data,"fghu data");
    

     const fetchData = async (page=1) => {
      const filterObject={

        start_date:date?.[0]||"",
      end_date:date?.[1]||"",
      date_search_type:filters.date_search_type,
      [filters.keyword]:filters.search
      }
      if(page==1&&count)
    setCount(0)
    //   const result = await request(`/sample/?sampleStatus=${status}`);
      const result = await request(`/sample/?${objectToQueryString(filterObject)}`);
setReset(false)
      if (!count||page==1) setCount(result.data.count);
    };

    const handlePageChange = (event, value) => {
        fetchData(value);
      };


      const handleViewReport = (barcode) => {
        navigate("/get-report/" + barcode);
      };

      useEffect(() => {
        fetchData()
        }, []);

        const [isDownloading,setIsDownloading]=useState(false)
        const handleDownload = async(barcode) => {
          setIsDownloading(true);
        const result = await apiClient.get(`report/download/${barcode}`);
        if (!result.ok) {
          toast.error("Error");
          setIsDownloading(false);
          return;
        }
    await  downloadFile(result.data.result)
        setIsDownloading(false);
      
        };
  
  return (
    <div>
         <Helmet>
        <title>
          Reports
        </title>
      </Helmet>
      <LoadingOverlay open={isDownloading}/>
        <Grid container>
        <div className='container'>
      <ShopFilterSidebar
        openFilter={showFilter}
        onOpenFilter={() => setShowFilter(true)}
        onCloseFilter={() => setShowFilter(false)}
        handleChange={onFilterChange}
        values={filters}
        onReset={onReset}
        fetchData={fetchData}
        date={date}
        setDate={setDate}
      />
      
      </div>
  <Grid className=' d-flex justify-content-center'  item md={12} xs={12}>
  <Grid item xs={12} md={8}  >
                <h5 className='my-3'>
                    Reports data:
                </h5>
{!loading?<>
  {data.map(({ client, technician, sampleStatus,...otherSampleItem }, index) => (
                <div className=' row justify-content-center d-flex gy-2 gx-2 flex-wrap px-4 mb-5'
                  
                style={{    backgroundColor: '#F1F1F5',
                // color:"white",            
                borderRadius: 15,
                alignItems: 'center',
                paddingTop:40,
                paddingBottom:40,
                }}
                >
                  <div className='col-1 d-flex align-items-start h-100'>
                  <AccountCircleIcon fontSize='large' />
                  </div>
            <div className='col-md-8' >
              <div className="row flex-wrap">

      {/* First set of information */}
     
      <div className="col-6">
        <Typography className='fs_14' variant="body2" sx={{ fontWeight: 'bold' }}>
          Name:
        </Typography>
        <Typography className='fs_10px' sx={{ fontWeight: 'bold' }}>
          {client.firstname + ' ' + client.lastname}
        </Typography>
      </div>

      <div className="col-6">
        <Typography className='fs_14' variant="body2" sx={{ fontWeight: 'bold' }}>
          Address:
        </Typography>
        <Typography className='fs_10px'>
          {client.address}
        </Typography>
      </div>

      {/* Second set of information */}
      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Email
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
          {client.email}
        </Typography>
      </div>

      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Contact
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
          {client.phone_no}
        </Typography>
      </div>

      {/* Third set of information */}
      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Pet Name
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
          {client.pet.pet_name}
        </Typography>
      </div>

      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Barcode
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
          {client.barcode}
        </Typography>
      </div>
      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Client Register Date
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
        {formatDate(client?.createdAt,'MMMM D, YYYY [at] h:mma')}
        </Typography>
      </div>
      <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Sample Receive Date
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
        {formatDate(otherSampleItem?.createdAt,'MMMM D, YYYY [at] h:mma')}
        </Typography>
      </div>
   {otherSampleItem?.submitted_at&&   <div className="col-6">
        <Typography variant="body2" component="p" sx={{  fontWeight: 'bold' }}>
          Report Create Date
        </Typography>
        <Typography variant="body2" component="p" sx={{  fontSize: '0.7rem' }}>
        {formatDate(otherSampleItem?.submitted_at,'MMMM D, YYYY [at] h:mma')}
        </Typography>
      </div>}
              </div>
    </div>
    <div className="col-md-3 d-flex flex-column flex-sm-row">

<Box className=''>
<Typography className='fs_14px' variant="span" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
Technician name
</Typography>
<Typography className='fs_14px' variant="span" sx={{ display: 'block', ml: 1 }}>
{technician?.name}
</Typography>
<Button sx={{}}
onClick={()=>handleDownload(client.barcode)}
disabled={sampleStatus!=="SUB"}  className=' m-2 btn btn_bg_secondery' variant="contained"  >
  Generate Report
</Button>
<Button sx={{}}
onClick={()=>handleViewReport(client.barcode)}
disabled={sampleStatus!=="SUB"}  className=' m-2 btn btn_bg_secondery' variant="contained"  >
  View Report
</Button>
</Box>
</div>
                  </div>
                ))}
        </>: <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
            <CircularProgress />
            </Box>}
         <div className={`${loading&&"d-none"}`}>
            <Paginate style={{ color: "white" }} count={count}  onChange={handlePageChange}/>
          </div>
        
  </Grid>

  </Grid>
</Grid>
    </div>
  )
}

export default ClientSubmittedReport