import * as React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {   CircularProgress, Paper,  } from '@mui/material'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, AppBar, Toolbar } from '@mui/material';
import BarcodeList from './Reactbarcode';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { useOutletContext } from 'react-router-dom/dist';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import apiClient from '../api/apiClient';
import useApi from '../hooks/useApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import SelectOption from './mui/SelectOption';
import Paginate from './mui/Paginate';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/modalSlice';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div className=''
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SampleTabs() {
  const initial = {
    sampleStatus: "REC",
  };
  const navigate = useNavigate();
  const {type}=useOutletContext()
  const { id } = useParams();
    const [value, setValue] = React.useState(0);
    const [smid, setsmId] = React.useState();
    const [show, setShowModal]= React.useState(false)
    const [ShowAssign, setShowAssign]= React.useState(false);
    const [technician, setTechnician] = useState(initial);
    const [count, setCount] = useState(0);


    const {request,error,data,loading}=useApi((route)=>apiClient.get(route))
// console.log(data,"fghu data");
    

     // const fetchData = async (page=1) => {
    //   if(page==1&&count)
    // setCount(0)
    //   const result = await request(`/sample/?sampleStatus=${status}`);
    //   if (!count||page==1) setCount(result.data.count);
    // };

    const handlePageChange = (event, value) => {
      fetchData(value);
    };

    const fetchData = async (page = 1) => {
     
    
      if (page === 1 && count) {
        setCount(0);
      }
    let statusQuery=status==="REC"?`sampleStatus=${status}`:`sampleStatus[$ne]=REC`
      const result = await request(`/sample/?limit=12&${statusQuery}&page=${page}`);
      if (!count || page === 1) {
        setCount(result.data.count);
      }
    };
    

   
const [status,setStatus]=useState("REC")
useEffect(() => {
fetchData()
}, [status]);
const handleDetailsClick = (id) => {
  navigate(`/admin/pet-bio/${id}`);
};

const handleViewReport = (barcode) => {
  navigate("/get-report/" + barcode);
};
    return (
      <Box className='d-flex justify-content-center' >
     <Grid className='' container md={10} xs={12} spacing={2} component={Paper} elevation={5} sx={{ marginTop: 5, padding:5, borderRadius:7 }}>
       <div className='d-flex gap-2'>
       <Button
         type='submit'
          variant={value === 0 ? 'contained' : 'outlined'}
          onClick={()=>{
            setValue(0)
            setStatus("REC")}}
          // onClick={() => handleChange(0)}
          sx={{
            backgroundColor: value === 0 ? '#3E4755' : 'none',
            color: value === 0 ? '#FFFFFF' : 'inherit',
            outline: 'none', // Remove the outline on focus
            '&:hover': {
              backgroundColor: value === 0 ? '#3E4755' : 'none', // Set the hover background color only when active
            },
            '&:active': {
              backgroundColor: '#3E4755', // Set the active background color
            },
          }}
        >
        Received Samples
      </Button>

      <Button
          variant={value === 1 ? 'contained' : 'outlined'}
          onClick={()=>{
            setValue(1)
            setStatus("ASS")}}
          sx={{
            backgroundColor: value === 1 ? '#3E4755' : 'none',
            color: value === 1 ? '#FFFFFF' : 'inherit',
            outline: 'none', // Remove the outline on focus
            '&:hover': {
              backgroundColor: value === 1 ? '#3E4755' : 'none', // Set the hover background color only when active
            },
            '&:active': {
              backgroundColor: '#3E4755', // Set the active background color
            },
          }}
        >
        Assigned Samples
      </Button>
       </div>

       <Grid container>

      <Grid className=' d-flex justify-content-center' container item md={12} xs={12}>
            <Grid item xs={12}>
              <TabPanel value={value} index={0}>
                {!loading?<>
               
             <Grid className='' >
                  <Box className=' d-flex justify-content-end'>
                      <Button sx={{borderRadius:20,}} onClick={()=>setShowModal(true)} className='m-2 btn_bg_secondery' variant="contained" color="primary"  >
                      Receive New Sample
                      </Button>
                  </Box>

               
               {data?.length>0&&data?.map(({client,_id}, index) => (
                  <Grid className=' d-flex justify-content-center'>
                      <Grid
                        key={index}
                        item
                        xs={12} // Takes full width on extra-small screens
                        md={12}  // Takes half width on medium screens
                      
                        sx={{
                          backgroundColor: '#F1F1F5',
                          // color:"white",
                          borderRadius: 3,
                          p: 1.5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent:'space-between',
                          my: 2, // Add margin top and bottom
                          flexWrap:"wrap",
                        }}
                        className=' row align-items-center py-5 px-3 w-100'
                      >
                         <div className=' col-8 d-flex align-items-center'>
                        <AccountCircleIcon fontSize='large' />
                        <Box sx={{ ml: 2 }}>
                        <Typography variant="body2" component="p" sx={{ display: 'block', ml: 0, fontWeight:"bold" }}>
                            Name
                          </Typography> 
                          <Typography variant="body1" sx={{fontSize: '0.6rem',fontWeight:'bold'}}>
                            {client?.firstname + " " + client.lastname}
                            </Typography>
                          
                          <Typography className='fs_14' variant="body2" sx={{ fontWeight:'bold' }}>
                            Address:
                          </Typography>
                          <Typography className='fs_10px'>
                          {client?.address}
                          </Typography>
                        </Box>
                        <Box sx={{ ml: 3 }}>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                            Email
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.email}
                          </Typography>

                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                          Contact
                          </Typography>
                          <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                            {client?.phone_no}
                          </Typography>
                        </Box>
                        </div>

                        <div className='col-4  px-5'>
                          <Box className=''>
                            <div>
                          <Button onClick={()=>{setShowAssign(true)
                          setsmId({id:_id,barcode:client.barcode})
                          }} className='m-2 btn_bg_secondery' variant="contained" color="primary"  >
                              Assign
                          </Button>
                            </div>
                          <div>

                          <Button
                          className='m-2 btn_bg_secondery'
                          variant="outlined"
                          color="primary"
                          onClick={() => handleDetailsClick(client._id)}
                        >
                          Details
                        </Button>

                        {/* <Link to={'/admin/pet-bio'}>
                          <Button className='m-2 btn_bg_secondery' variant="outlined" color="primary" >
                              Details
                          </Button>
                          </Link> */}


                          </div>
                          </Box>
                        </div>
                    

                        
                         
                      </Grid>
        
                    </Grid>
            ))}

              </Grid>

              <AssignModal
            data={smid}
            fetchData={fetchData}
                show= {ShowAssign}
                onHide={()=>{setShowAssign(false)}}
                />
              
              <NewSampelModal
                            fetchData={fetchData}
                                show={show}
                                onHide={()=>setShowModal(false)}
                                  />
           
           </>: <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
                  <CircularProgress />
                  </Box>}
              </TabPanel>
            </Grid>
      </Grid>

       </Grid>


<Grid container>
  
        <Grid className=' d-flex justify-content-center' container item md={12} xs={12}>
        <Grid item xs={12}>
              <TabPanel value={value} index={1}>
               {!loading?<>
                {data.map(({client,technician,sampleStatus}, index) => 
                (
                  <Grid className=' '  >
                    
                        <Grid
                          key={index}
                          item
                          xs={12} // Takes full width on extra-small screens
                          md={12}  // Takes half width on medium screens
                          sx={{
                            backgroundColor: '#F1F1F5',
                            // color:"white",
                            borderRadius: 3,
                            p: 1.5,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:'space-between',
                            my: 2, // Add margin top and bottom
                            flexWrap:"wrap",
                          }}
                          className=' row align-items-center py-5 px-3 w-100'
                        >
                          <div className=' col-8 d-flex align-items-center'>

                          <AccountCircleIcon fontSize='large' />
                          <Box className="" sx={{  ml: 2}}>
                            <Typography variant="body1" className='fs_14px' sx={{fontWeight:"bold"}}>
                                 Name
                              </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>
                              {client?.firstname + " " + client.lastname}
                            </Typography>
                            <Typography variant="body2" className='fs_14px' sx={{  fontWeight:"bold" }}>
                            Address:
                            </Typography>
                            <Typography className='fs_10px'>
                            {client?.address}
                            </Typography>
                          </Box>
                          <Box className='' sx={{ ml: 3 }}>
                            <Typography variant="body2" className='fs_14px'  component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold" }}>
                              Email
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                              {client?.email}
                            </Typography>

                            <Typography className='fs_14px'  variant="body2" component="p" sx={{ display: 'block', ml: 1, fontWeight:"bold"  }}>
                            Contact
                            </Typography>
                            <Typography variant="body2" component="p" sx={{ display: 'block', ml: 1,fontSize: '0.7rem' }}>
                              {client?.phone_no}
                            </Typography>
                          </Box>
                          </div>
                          <div className=' col-4'>
                          <Box className=''>
                            <Typography className='fs_14px' variant="span" sx={{ display: 'block', ml: 1, fontWeight:'bold' }}>
                              Technician name
                              </Typography>
                              <Typography className='fs_14px' variant="span" sx={{ display: 'block', ml: 1 }}>
                            {technician?.name}
                              </Typography>
                            <Button sx={{}}
                            onClick={()=>handleViewReport(client.barcode)}
                            disabled={sampleStatus!=="SUB"}  className=' m-2 btn btn_bg_secondery' variant="contained"  >
                                View Reports
                            </Button>
                            </Box>
                          </div>
                          {/* <Box className="" sx={{ width: '125px', height: '125px', overflow: 'hidden' }}>
                                <BarcodeList key={index} value={0} />
                            </Box> */}


                         
                          

                        </Grid>
                  </Grid>
                  ))}</>: <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
                  <CircularProgress />
                  </Box>}
               <div className={`${loading&&"d-none"}`}>
                  <Paginate style={{ color: "white" }} count={count}  onChange={handlePageChange}/>
                </div>
              </TabPanel>
        </Grid>

        </Grid>
</Grid>

      </Grid>
       
      </Box>
    );
    function AssignModal(props) {
      let initialstate={
        sampleStatus:"ASS",
        technician:"",
      }
      const dispatch = useDispatch()
      const [sample ,setSample]=useState(initialstate)
      const [load ,setLoad]=useState(false)
      const handleChange=(key,value)=>{
        setSample({...sample,[key]:value})
      }
      const handleSubmit=async(e)=>{
        e.preventDefault()
        setLoad(true)
        const result=await apiClient.put(`/sample/${props.data.id}`,sample)
        setLoad(false)
        if(!result.ok) return     dispatch(showModal({
          error:true,
          title: 'Sample',
          message: result.data.message,
        }));
        props.fetchData()
        props.onHide()
      }
      return (
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className=' ' closeButton>
            <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
            <HelpIcon style={{ fontSize: '55px', color: '#7C828C' }} />
            </Modal.Title>
          </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <h4 className='text-center'>Assign this Sample</h4>
            <p className='text-center'>
            Assign this sample to a specific <br />
            technician.
            </p>
            <div className='d-flex justify-content-center'>
            <div className='col-md-8'>
           
            <SelectDropdown  label="Technician" sx={{backgroundColor:"white"}} query={`${props?.data?.barcode?.includes("pp")?"role[$ne]=AD":"role=TN"}`}  endpoint={`auth/all-users-t/`} value={sample.technician} name={"technician"} handleChange={handleChange}/>
            </div>
            </div>
    
          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center'>
            <Button  type='submit'
            // onClick={()=>handleSubmit()} 
            variant='contained' className=' btn_bg_secondery px-5'>Save
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
      );
    }
  }



  function NewSampelModal(props) {
    let initialstate={
      sampleStatus:"REC",
      barcode:"",
    }
    const dispatch=useDispatch()
    const [sample ,setSample]=useState(initialstate)
    const [load ,setLoad]=useState(false)
    const handleChange=(key,value)=>{
      setSample({...sample,[key]:value})
    }
    const handleSubmit=async(e)=>{
      e.preventDefault()
      setLoad(true)
      const result=await apiClient.post("/sample/",sample);
      setLoad(false)
      props.onHide();
      if(!result.ok)  return     dispatch(showModal({
        error:true,
        title: 'Sample',
        message: result.data.message,
      }));
      dispatch(showModal({
        title: 'Sample',
        message: 'Successfully Sample Received',
      }));
      setLoad(false)
      props.fetchData()
      props.onHide()
    }
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className=' ' closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
          <HelpIcon style={{ fontSize: '55px', color: '#7C828C' }} />
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
          <h4 className='text-center'>Receive New Sample</h4>
          <p className='text-center'>
          Please enter the code to receive the sample. An <br />
          email will be sent to the user stating that your sample <br />
          has been received.
          </p>
          <div className='d-flex justify-content-center'>
          <div className='col-md-8'>
          <label htmlFor="barcode">Code</label>
          <input type="text" name=""  id="barcode" className="form-control" value={sample.barcode} onChange={(e)=>handleChange("barcode",e.target.value)} />
          </div>
          </div>
  
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          {!load?<Button type='submit' variant='contained' className='btn_bg_secondery px-5'>Save</Button>:
           <Box sx={{ display: 'flex',justifyContent:"center" }}>
           <CircularProgress />
         </Box>}
        </Modal.Footer>
        </form>
      </Modal>
    );
  }



  const SelectDropdown = ({endpoint,name,value,query, handleChange,...otherProps}) => {
    const [data, setData] = useState([]);
    const [page,setPage]=useState(1)
    const [count,setCount]=useState()
    const {request}=useApi((route)=>apiClient.get(route))
  useEffect(()=>{
  
    fetchData()
  },[])
   
    async function fetchData(){
      const result=await request(`/${endpoint}?page=${page}&${query}`)
      if(result.data.results)
      setData([...data,...result.data?.results])
      setPage(page+1)
      if(!count)
      setCount(result)
     }
    
     const handleScroll = (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight-1) {
        if(count>data.length)
        fetchData();
      }
    };
  
    return (
        <SelectOption
        fieldNames={{value:"_id",name:"name"} } 
        required
        inputProps={{ maxLength: 150 }}
        id="outlined-name"
        value={value}
        onChange={(e)=>handleChange(name,e.target.value)}
       size={"small"}
        data={data}
        fullWidth
        style={{width:"100%" }}
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
            style: {
              maxHeight: "300px",
              overflowY: "auto",
            },
          },
        }}
        {...otherProps}
      />
    );
  };



 
  