import Modal from 'react-bootstrap/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { Button, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/modalSlice';
import SelectOption from './mui/SelectOption';
const uuid = require('uuid');
function CenterModal(props) {
  const dispatch=useDispatch()
  const[qrr,setQrr]=useState()
  const [barcodeType,setType]=useState("")
  const generateUniqueBarcode = () => {
    // Generate a UUID
    const uniqueId = uuid.v4();
  
    // Remove non-numeric characters and take the first 13 digits
    const numericId = uniqueId.replace(/[^0-9]/g, '').slice(0, 13);
    // return barcodeType=="pp"?numericId+"pp":numericId;
    const barcodeTypeSuffix = barcodeType === "pp" ? "pp" : barcodeType === "Exp" ? "Exp" : "";
    return numericId + barcodeTypeSuffix;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setQrr("");
    const generateObjectArray = () => {
      const newArray = [];
      for (let i = 0; i < qrr; i++) {
        const randomNumber = generateUniqueBarcode()
        const newObj = { key: randomNumber };
        newArray.push(newObj);
      }
      return newArray;
    };
    //console.log(generateObjectArray());
    const result = await apiClient.post("/qr", generateObjectArray());
    if (!result.ok) return  dispatch(showModal({
      error:true,
      title: 'Barcode',
      message: result.data.message,
    }));
    dispatch(showModal({
      title: 'Barcode',
      message: 'Bar codes generated successfully.',
    }));
    setTimeout(() => {
    props.fetchData()
    }, 3000); // Delay the page reload for 2 seconds (adjust as needed)
    //console.log(result);
    props.onHide();
  };
  
  
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-dialog-centered" // Add this class
    >
      <Modal.Header className=' ' closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
        <HelpIcon style={{ fontSize: '50px', color: '#737983' }} />
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
      <Modal.Body>
        <h4 className='text-center'>Generate Barcode</h4>
        <p className='text-center'>
        Please give me the number of barcodes you want to generate.
        </p>
        {/* <div className='d-flex justify-content-center'>
        <div className='col-md-8'>
        <label htmlFor="barcode">No. Of Barcodes</label>
        <input type="text"  name="" id="barcode" className="form-control" />
        </div>
        </div> */}
   
        <Grid container style={{justifyContent:"center"}} spacing={2}>

        <Grid item xs={8}>
      <TextField
      required
     InputProps={{ inputProps: { min: 0 } }}
       fullWidth  type='number'  id="outlined-basic"value={qrr} onChange={(e)=>setQrr(e.target.value)} label="No. of Barcodes" variant="outlined" />
      </Grid>
      <Grid item  xs={8}>
      <SelectOption required style={{width:"100%"}}  
      data={[
      {value:"normal",label:"Normal"},
      {value:"pp",label:"PP Barcodes"},
      {value:"Exp",label:"EXP Barcodes"}
      ]}  id="outlined-basic" value={barcodeType} 
      onChange={(e) => {
        setType(e.target.value);
        // console.log(e.target.value);
      }}
       label="Barcode Type" variant="outlined" />
      </Grid>
      </Grid>

      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
        <Button fullWidth sx={{borderRadius:20}} type='submit' variant='contained' className='btn_bg_secondery px-5'>Save</Button>
      </Modal.Footer>
      </form>
    </Modal>
  );
}
export default CenterModal
