import React from 'react';
import { Modal } from 'react-bootstrap';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error'; // import an error icon

function ToastModal({ values, onHide }) {
  const { show, error, message, title, icon } = values;
  const IconComponent = error ? ErrorIcon : (icon || CheckCircleIcon);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100 ms-4'>
          <IconComponent style={{ fontSize: '50px', color: error ? '#FF0000' : 'green' }} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>{title}</h4>
        <p className='text-center' style={{ color: error ? '#FF0000' : '#000000' }}>{checkIfStringAndShow(message)}</p>
      </Modal.Body>
    </Modal>
  );
}

export default ToastModal;

const checkIfStringAndShow=(value)=>{
  if(typeof value==="string")
  return value
return "Error occured please try again."
}


