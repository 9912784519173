import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import BackButton from "../components/mui/BackButton";
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/modalSlice';
import SelectOption from '../components/mui/SelectOption';

function AddnewSubadmin () {
  const navigate=useNavigate()
  const initailState={
    name:"",
    email:"",
    contact:"",
    password:'',
    confirm_password:"",
    role:"SA"
  }
  
  const [load,setLoad]=useState(false)
  const [technician,setTectnician]=useState(initailState)
  const [message, setMessage] = useState({text:"",color:""});
  const dispatch = useDispatch()
  const handleChange=(key,value)=>{
    setTectnician({...technician,[key]:value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoad(true)
    if(technician.password!=technician.confirm_password){
      setLoad(false)
return setMessage({text:"Password doesn't match please enter again",color:"danger"})}else{
 
  resetErrors()
}
const result =await apiClient.post("/auth/sub-admin",technician)
setLoad(false)
//console.log(result);
if(!result.ok)  return     dispatch(showModal({
  error:true,
  title: 'Sub-admin',
  message: result.data.message,
}));
  navigate("/admin/sub-admin")
  }
  function resetErrors() {
    setMessage({text:"",color:""})
  }
  return (
    <div>
      <Container className='' maxWidth='lg'>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex align-items-center'>
          <div className='px-1 rounded' >
            {/* <Link to={'/admin/client'} style={{ color: '#000000' }}>
              <ArrowBackIosIcon />
            </Link> */}
            <BackButton to={'/admin/sub-admin'}/>
          </div>
              <h4 className='ps-1 mb-0 fw-bold'>
              Add New Subadmin:
              </h4>
            </div>
          </Grid>
    </Grid>
    <Grid md={10} xs={12}  spacing={2} component={Paper} elevation={5} sx={{marginTop:5, padding:5}}>
    
<form onSubmit={handleSubmit}>
    <Grid md={10} xs={12}>
    <Grid container spacing={2}>
      
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='text'   id="outlined-basic" value={technician.name} onChange={(e)=>handleChange("name",e.target.value)} label=" Name" variant="outlined" />
      </Grid>
      {/* <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Last Name" variant="outlined" />
      </Grid> */}
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='email'   id="outlined-basic" label="Email" value={technician.email} onChange={(e)=>handleChange("email",e.target.value)} variant="outlined" />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='number'  id="outlined-basic" label="Contact" value={technician.contact} onChange={(e)=>handleChange("contact",e.target.value)} variant="outlined" />
      </Grid>
    </Grid>
    <Grid sx={{py:2}} container spacing={2}>
      <Grid item md={4} xs={12}>
      <TextField fullWidth  type='password'  id="outlined-basic" value={technician.password} onChange={(e)=>handleChange("password",e.target.value)} label="Password" variant="outlined" />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='password' onFocus={resetErrors}  id="outlined-basic" value={technician.confirm_password} onChange={(e)=>handleChange("confirm_password",e.target.value)}label="Re-enter Password" variant="outlined" />
      <Grid item xs={12}>
        <p style={{color:'red'}}>{message.text}</p>
      </Grid>
      </Grid>
    </Grid>
    
    </Grid>
    <Grid sx={{py:2}}>
      {!load?
          <Button className='btn_bg_secondery ' type='submit' variant='contained'>
               Save
          </Button>:
          <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
            <CircularProgress />
          </Box>}
      
        
    </Grid>
    </form>
    </Grid>
    
      </Container>
    </div>
  )
}
export default AddnewSubadmin
