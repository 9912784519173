import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// import "./styles/Barcode.css";
import { useBarcode } from 'next-barcode';
import html2pdf from "html2pdf.js";
const TestBarcode = ({  }) => {
const barcodes=[
    "7648014063684pp","2047824148233exp","2047824148233exp","7287824845906","7648014063684pp",
    "7648014063684pp","2047824148233exp","2047824148233exp","7287824845906","7648014063684pp",
    "7648014063684pp","2047824148233exp","2047824148233exp","7287824845906","7648014063684pp",
    "7648014063684pp","2047824148233exp","2047824148233exp","7287824845906","7648014063684pp",
    "7648014063684pp","2047824148233exp","2047824148233exp","7287824845906","7648014063684pp",
 
]
  const generatePDF = () => {
    const element = document.getElementById("barcode-list-container");
        const options = {
            // margin:       5,
            filename: "report.pdf", // Specify the filename for the downloaded PDF
            jsPDF: { unit: "mm", format: [50.8, 25.4], orientation: "landscape" } // Set the PDF format and orientation
           };
        
        html2pdf().set(options).from(element).save();
      
  };

  return (
    <div>
      <button onClick={generatePDF}>Download PDF</button>
      <div id="barcode-list-container" >
        {barcodes.map((barcode, index) => (
          <div key={index} >
            <BarcodeItem value={barcode}/>
          </div>
        ))}
      </div>
    </div>
  );
};


function BarcodeItem({ value, url="www.gphtest.com" }) {
    const { inputRef } = useBarcode({
        value: value,
        options: {
          background: '#F5F5F5',
          width: 2,
          height: 35,
        },
      });
    
      return (
        <div style={{
          backgroundColor: "#F3EFEF",
          width: '50.8mm',
          height:"25.4mm",
          padding:"3mm",
          paddingTop:"1.5mm",
          paddingBottom:"1.5mm",
        }}>
          <div className='d-flex justify-content-center' style={{height:"15mm"}}>
            <svg
              style={{ width: '100%' }}
              // height="20mm" 
              ref={inputRef}
            />
          </div>
          <div className='text-center'  style={{height:"2.4mm"}}>
            <span style={{ color: "black" }}>
              {url}
            </span>
          </div>
        </div>
      );
  }
export default TestBarcode;
