import { Search } from '@mui/icons-material'
import { Box, Button, Container, Grid, IconButton, InputBase, Paper } from '@mui/material'
import React, { useState } from 'react'
import BarcodeList from '../components/Reactbarcode'
import ClientlistCard from '../components/ClientlistCard'
import { Link } from 'react-router-dom'
import Paginate from '../components/mui/Paginate'
import {Helmet} from "react-helmet";

function Client ()  {
  const [searchQuery, setSearchQuery] = useState("");


  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
 
  return (
    <div>
      <Helmet>
        <title>
          Client
        </title>
      </Helmet>
      <Container className='' maxWidth='lg'>
      <Grid container className=' d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
              <h3 className='mb-0'>
              Client List:
              </h3>
              {/* <div className='d-flex justify-content-between align-items-center px-4 col-md-6' style={{ backgroundColor: "#f1f1f5", borderRadius: "50px" }}>
  <InputBase
    className=''
    sx={{
      ml: 1,
      flex: 1,
      px: 1,
    }}
    placeholder="Search..."
    type='search'
    value={searchQuery} onChange={handleSearchInputChange}
  />
  <Box sx={{ width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', p:1  }}>
    <IconButton
      type="button"
      sx={{
        p: '5px',
        backgroundColor: '#3E4755',
        color: 'white',
        '&:hover': {
          backgroundColor: '#3E4755',
          color: 'white',
        },
      }}
      aria-label="search"
    >
      <Search style={{ fontSize: 25 }} />
    </IconButton>
  </Box>
</div> */}
              {/* <Link className='mt-3 mt-sm-0' to={'/admin/addnew-client'}>
              <Button sx={{borderRadius:20, fontSize:10}} className='btn_bg_secondery' variant='contained'>
                Add new Client
              </Button>
              </Link> */}
            </div>
          </Grid>
    </Grid>


    <Grid className='d-flex justify-content-center flex-wrap' container>
      <Grid md={10} xs={12}  spacing={2} className='shadow' sx={{marginTop:5, padding:1 , borderRadius:10}}>
       <ClientlistCard searchQuery={searchQuery} />
      </Grid>

    </Grid>
    {/* <Paginate style={{ color: "white" }} count={count}  onChange={handlePageChange}/> */}
      </Container>
    </div>
  )
}
export default Client
