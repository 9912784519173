import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  InputBase,
} from '@mui/material';
// components
import Iconify from './Iconify';
import Scrollbar from './scrollbar';
import Calender from '../mui/Calendar';
import SearchHome from '../mui/SearchBar';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'; // Non-active icon
import FavoriteIcon from '@mui/icons-material/Favorite'; // Active icon



export default function ShopFilterSidebar({ openFilter, onOpenFilter, onCloseFilter,handleChange,values,fetchData,onReset, date, setDate }) {
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 400, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
     

       

       
          <div>
              <Typography variant="subtitle1" gutterBottom>
               Search
              </Typography>
              <div className='my-2'>
                
              <SearchHome
  data={[
    { route: "barcode", name: "Barcode" } ,
    { route: "name", name: "Client" },
    { route: "pet_name", name: "Pet Name" } ,
  ]}
  search={values.search}
  setSearch={(value)=>handleChange("search",value)}
  setKeyword={(value)=>handleChange("keyword",value)}
  keyword={values.keyword}
  style={{ minWidth: "320px" }}
/>
              </div>
    
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Date Filter Type
              </Typography>
              <div className='my-2'>
                
              <ToggleButtonGroup
  color="primary"
  value={values.date_search_type}
  onChange={(e,value)=>handleChange("date_search_type",value)}
  exclusive
  aria-label="Date Filter"
>
  <ToggleButton value="client">Client</ToggleButton>
  <ToggleButton value="sample">Sample</ToggleButton>
  <ToggleButton value="report">Report</ToggleButton>
</ToggleButtonGroup>
              </div>
              <Calender
           date={date}
           setDate={setDate}
                   
                   />
                        
            </div>
<div className='d-flex justify-content-center'>

            <Button sx={{borderRadius:20, fontSize:10,height:"35px",width:"150px"}} onClick={(e)=>fetchData()} className='btn_bg_secondery px-2 ' variant='contained'>
               Filter
              </Button>
</div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
          onClick={onReset}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
export  function SampleShopFilterSidebar({ openFilter, onOpenFilter, onCloseFilter,handleChange,values,fetchData,onReset, date, setDate }) {
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 400, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
     

       

       
          <div>
              <Typography variant="subtitle1" gutterBottom>
               Search
              </Typography>
              <div className='my-2'>
                
              <SearchHome
  data={[
    { route: "barcode", name: "Barcode" } ,
    { route: "name", name: "Client" },
    { route: "pet_name", name: "Pet Name" } ,
  ]}
  search={values.search}
  setSearch={(value)=>handleChange("search",value)}
  setKeyword={(value)=>handleChange("keyword",value)}
  keyword={values.keyword}
  style={{ minWidth: "320px" }}
/>
              </div>
    
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Date Filter Type
              </Typography>
              <div className='my-2'>
                
              <ToggleButtonGroup
  color="primary"
  value={values.date_search_type}
  onChange={(e,value)=>handleChange("date_search_type",value)}
  exclusive
  aria-label="Date Filter"
>
  <ToggleButton value="client">Client</ToggleButton>
  <ToggleButton value="sample">Sample</ToggleButton>
</ToggleButtonGroup>
              </div>
              <Calender
           date={date}
           setDate={setDate}
                   
                   />
                        
            </div>
<div className='d-flex justify-content-center'>

            <Button sx={{borderRadius:20, fontSize:10,height:"35px",width:"150px"}} onClick={(e)=>fetchData()} className='btn_bg_secondery px-2 ' variant='contained'>
               Filter
              </Button>
</div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
          onClick={onReset}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}
export  function ClientShopFilterSidebar({ openFilter, onOpenFilter, onCloseFilter,handleChange,values,fetchData,onReset, date, setDate }) {
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 400, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
     

       

       
          <div>
              <Typography variant="subtitle1" gutterBottom>
               Search
              </Typography>
              <div className='my-2'>
                
              <SearchHome
  data={[
    { route: "barcode", name: "Barcode" } ,
    { route: "name", name: "Client" },
    { route: "pet_name", name: "Pet Name" } ,
  ]}
  search={values.search}
  setSearch={(value)=>handleChange("search",value)}
  setKeyword={(value)=>handleChange("keyword",value)}
  keyword={values.keyword}
  style={{ minWidth: "320px" }}
/>
              </div>
    
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                Client Register Date
              </Typography>
    
              <Calender
           date={date}
           setDate={setDate}
                   
                   />
                        
            </div>
<div className='d-flex justify-content-center'>

            <Button sx={{borderRadius:20, fontSize:10,height:"35px",width:"150px"}} onClick={(e)=>fetchData()} className='btn_bg_secondery px-2 ' variant='contained'>
               Filter
              </Button>
</div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
          onClick={onReset}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}




export  function BarcodeFilterSidebar({ openFilter, onOpenFilter, onCloseFilter,handleChange,values,fetchData,onReset }) {
  return (
    <>
      <Button disableRipple color="inherit" endIcon={<Iconify icon="ic:round-filter-list" />} onClick={onOpenFilter}>
        Filters&nbsp;
      </Button>

      <Drawer
        anchor="left"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 400, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Filters
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
     

       

       
          <div>
              <Typography variant="subtitle1" gutterBottom>
               Search
              </Typography>
              <div className='my-2'>
              <div className='d-flex justify-content-between align-items-center px-4 py-1' style={{ backgroundColor: "#f1f1f5", borderRadius: "50px" }}>
                  <InputBase
                     className=''
                     sx={{
                       ml: 1,
                       flex: 1,
                       px: 1,
                     }}
                     placeholder="Search by barcode..."
                     type="search"
                     value={values.barcode}
                     onChange={(e) => handleChange("barcode",e.target.value)}
                   />
         
            </div>
     
              </div>
    
            </div>
            <div>
              <Typography variant="subtitle1" gutterBottom>
               
              </Typography>
              <div className='my-2'>
              <div className='d-flex justify-content-between align-items-center px-4' >
            
              <ToggleButtonGroup
  color="primary"
  value={values.activated}
  onChange={(e,value)=>handleChange("activated",value)}
  exclusive
  aria-label="Activated"
>
  <ToggleButton value="">All</ToggleButton>
  <ToggleButton value={true}>Activated</ToggleButton>
  <ToggleButton value={false}>Not Activated</ToggleButton>
</ToggleButtonGroup>
            </div>
     
              </div>
    
            </div>
<div className='d-flex justify-content-center'>

            <Button sx={{borderRadius:20, fontSize:10,height:"35px",width:"150px"}} onClick={(e)=>fetchData()} className='btn_bg_secondery px-2 ' variant='contained'>
               Filter
              </Button>
</div>
          </Stack>
        </Scrollbar>

        <Box sx={{ p: 3 }}>
          <Button
          onClick={onReset}
            fullWidth
            size="large"
            type="submit"
            color="inherit"
            variant="outlined"
            startIcon={<Iconify icon="ic:round-clear-all" />}
          >
            Clear All
          </Button>
        </Box>
      </Drawer>
    </>
  );
}