import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import BackButton from "../../components/mui/BackButton";
import { Box } from '@mui/system'
import { Button, Checkbox, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import apiClient from '../../api/apiClient'
import useApi from '../../hooks/useApi'
import { toast } from 'react-toastify'
import { addReportData, removeReportData } from '../../redux/reportSlice'
import { useDispatch, useSelector } from 'react-redux'
const energeticRefData=[
  {
    "name": "Candida",
    "items": [
      "Yeast Culture",
      "Baker's Yeast",
      "Brewer's Yeast",
      "Nutritional Yeast",
      "Sugar",
      "Potato",
      "Parsnip",
      "Honey",
      "Molasses",
      "Stevia",
      "NutraSweet",
      "Sweet Potato",
      "Turnip",

    ]
  },
  {
    "name": "Fungus",
    "items": [
      "Mushrooms * (All - including medicinal)",
      "Parsnip",
      "Potato",
      "Sweet Potato",
      "Turnip",
      "Yeast Culture",
      "Baker's Yeast",
      "Brewer's Yeast",
      "Nutritional Yeast",
    ]
  },
  {
    "name": "Mold",
    "items": [
      "Parsnip",
      "Potato",
      "Sweet Potato",
      "Turnip",
      "Yeast Culture",
      "Baker's Yeast",
      "Brewer's Yeast",
      "Nutritional Yeast",
    ]
  },
  {
    "name": "Yeast",
    "items": [
      "Parsnip",
      "Potato",
      "Sweet Potato",
      "Turnip",
      "Yeast Culture",
      "Baker's Yeast",
      "Brewer's Yeast",
      "Nutritional Yeast",
    ]
  },
  {
    "name": "Species: Cat",
    "items": [
      "Herbaprin",
      "Rosemary"
    ]
  },
  {
    "name": "Inflammation",
    "items": [
      "Salmon*",
      "Lamb",
      "Tomato",
      "Ethoxyquin",
      "Salmon Oil",
    ]
  },
  {
    "name": "Chlorine",
    "items": [
     "Chlorine"
    ]
  },
]
const Reports = () => {

const apiConditional=useApi(()=>apiClient.get('/if-then/'))
const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const client = queryParams.get('client');
  const reportDataOld=useSelector(state=>state.report.report)
const fetchDataConditional=async()=>{
  const result=await apiConditional.request()
  if(!result.ok) toast.error("can't get data if-then")
}

  const [isChecked1, setIsChecked1] = useState(false);
  const [selectedText, setSelectedText] = useState(reportDataOld?.selectedText||'');
  const [gridData, setGridData] = useState(reportDataOld?.gridData||[]);
  const [selectd, setSelected] = useState(reportDataOld?.selected||[]);
  const [reportData, setReportData] = useState();
  const [load ,setLoad]=useState(false);
  const [clData, setClData] = useState(reportDataOld?.clData||{});
  const [date,setDate]=useState(reportDataOld?.date||"")
  const {barcode}=useParams()
  const {state}=useLocation()
  const dispatch=useDispatch()
  //console.log(state, "state")
//console.log(selectd,"selects");
useEffect(()=>{
  try {
    
    dispatch(addReportData({barcode,selected:selectd,selectedText:selectedText,date,gridData,clData}))
  } catch (error) {
    console.log("failed to persist")
  }
  
},[selectedText,selectd])

  const handleTextClick = (text) => { 

    const isSelected = selectedText.includes(text);
    let updatedSelectedText;

    if (isSelected) {
      // Remove the selected element
      updatedSelectedText = selectedText.filter((item) => item !== text);
    } else {
      // Add the selected element
      updatedSelectedText = [...selectedText, text];
    }
    if(isSelected)
    {
      removeEnergeticimbalance(text)
    }else

    addEnergeticimbalance(text)

    setSelectedText(updatedSelectedText);
  };


 const fetchData=async(id)=>{
  const result=await apiClient.get("/report/raw-data/");
  if(!result.ok)return 
  //console.log(result.data, "SDDSADS");
  let categorizedData=result.data
  if(!barcode.includes("pp"))
  categorizedData=categorizedData.filter(x=>!["GPH Products","May Also Be Helpful:","Cell Salts:","Thyroid","Virus","Bacteria","Inflammation","Pain"].includes(x.category))
  addToxic(categorizedData)
  setGridData(categorizedData); 
  
  setLoad(false);

  const clientResult = await apiClient.get(`/client/${client}`);
    if (!clientResult.ok) return;
    setLoad(true)
    const clientData = clientResult.data.result;
    // //console.log(clientData)
    setClData(clientData);
    setLoad(false);

  // //  First fetch request
  //  const result2 = await apiClient.get(`/client/${id}`);
  //  if (!result2.ok) return;
  //  //console.log(result2.data, 'client data');

 }

  useEffect(() => {
    if(!reportDataOld?.gridData){

      setLoad(true)
      fetchData()
    }
    fetchDataConditional()

  }, [])
  //console.log(clData, "Client Data");
  const [report,setReport]=useState([])
  const handleChange = (id,name,senstivity) => {
    try {
      
      const conditionalData=apiConditional.data
   
       const existingItemIndex = selectd.findIndex(item => item.id === id);
       const selectedItem = conditionalData.find(item => item.itemId === id);
     if (existingItemIndex !== -1) {
       let updatedSelectd = _.cloneDeep(selectd);
       let existingItem=updatedSelectd[existingItemIndex]
       if(existingItem.senstivity==senstivity){
   
       updatedSelectd=updatedSelectd.filter(x=>x.id!==id)
         
      if(selectedItem)
      {     const itemsToRemove = [];
           
           for (const item of selectedItem.items) {
             if (updatedSelectd.some(existingItem => existingItem.id === item)) {
               itemsToRemove.push(item);
             }
           }
           
           const updatedSelectdAfterRemoval = updatedSelectd.filter(existingItem => {
             return !itemsToRemove.includes(existingItem.id);
           });
         return  setSelected(updatedSelectdAfterRemoval);
         }
       }
       else
       {
   // console.log(existingItem,"ex")
         existingItem.senstivity = senstivity;
         if (selectedItem) {
           const itemsToUpdate = [];
             
           for (const item of selectedItem.items) {
             const relatedItemIndex = updatedSelectd.findIndex(existingItem => existingItem.id === item);
             if (relatedItemIndex !== -1) {
               updatedSelectd[relatedItemIndex].senstivity = senstivity;
               itemsToUpdate.push(item);
             }
           }
         }
       }
       setSelected(updatedSelectd);
     } else {
       // If the name doesn't exist, add it to the selectd array
   
       setSelected([...selectd, { id, senstivity }]);
     }
   
   
       
     if (selectedItem&&existingItemIndex==-1) {
       const updatedSelectd = [...selectd];
     
       
         updatedSelectd.push({ id, senstivity });
         for (const item of selectedItem.items) {
           // Check if the item already exists in selectd to avoid duplicates
           if (!updatedSelectd.some(existingItem => existingItem.id === item)) {
             updatedSelectd.push({ id: item, senstivity });
             
           }
          
         }
         setSelected(updatedSelectd);
       
         selectd.push({name,senstivity})
      
     }
    } catch (error) {
      console.log(error)
    }
}

const addToxic=(gridData)=>{
  const updatedSelectd = [...selectd];
  const data=gridData.find(x=>x.category==="Toxic Foods").items
  // console.log(data,"datai")
  for (const item of data) {
      updatedSelectd.push({ id: item._id, senstivity:"HG" });
      
    
}

setSelected(updatedSelectd);

  };
  function addEnergeticimbalance(text){
    let foundData=energeticRefData.find(x=>x.name==text)
    if(!foundData)
    return
    let ItemsToFind=foundData.items
    const matchingItems = gridData.flatMap(category => 
      category.items.filter(item => ItemsToFind.includes(item.name))
    );
    //console.log(matchingItems,"matched");

      const updatedSelectd = [...selectd];
      for (const item of matchingItems) {
        // Check if the item already exists in selectd to avoid duplicates
        if (!updatedSelectd.some(existingItem => existingItem.id === item)) {
          updatedSelectd.push({ id: item._id,senstivity: "MD" });
          
        }
       
      }
  
      setSelected(updatedSelectd);
  }
  function removeEnergeticimbalance(text){
    let foundData=energeticRefData.find(x=>x.name==text)
    if(!foundData)
    return
    let ItemsToFind=foundData.items
    const matchingItems = gridData.flatMap(category => 
      category.items.filter(item => ItemsToFind.includes(item.name))
    );
    //console.log(matchingItems,"matched");

    const updatedSelectd = [...selectd];

    // Define a function to check if an item should be removed
    const shouldRemoveItem = (itemToRemove) => {
      return matchingItems.some(item => item._id === itemToRemove.id);
    };
    
    // Use the filter method to remove items that meet the condition
    const updatedSelectdAfterRemoval = updatedSelectd.filter(item => !shouldRemoveItem(item));
    
  
      setSelected(updatedSelectdAfterRemoval);
  }
  function findInGridDataByName(items, sign) {
    
    if(selectd.length==0||!items.some((item) => selectd.some((obj) =>obj.id === item._id)))
    return false
    const asterisksCount = sign.length;
    let regex=new RegExp(`^[^*]*\\*{${asterisksCount}}$`);
      const filteredData = items.filter((item) => regex.test(item.name));
      
    return filteredData ? filteredData.some((item) => selectd.some((obj) =>obj.id === item._id)) : false;

  }


  const handleRemove=(id)=>{
let dataFiltered=selectd.filter(x=>x.id!==id)
setSelected(dataFiltered)
  }
  const navigate=useNavigate()
  function handleNavigate(e) {
    e.preventDefault()
    //console.log(selectd)
    let data = gridData.map(item => {
      let item1 = item.items.filter(data => selectd.some(reportItem => reportItem.id === data._id));

      if (item1.length > 0) {
        let matchedItems = item1.map(matchedItem => {
          let sensitivityItem = selectd.find(reportItem => reportItem.id === matchedItem._id);
          //console.log(sensitivityItem,"sens")
          return { ...matchedItem, senstivity: sensitivityItem.senstivity };
        });
        return { ...item, items: matchedItems };
      }
      return null;
    }).filter(item => item !== null);
    navigate("/tn/final-report/"+barcode,{state:{data,imbalance:selectedText,selectd,date}})
}
//console.log(apiConditional.data)
//console.log(selectd,"selected");

  return (
    <div>
      <form onSubmit={handleNavigate}>
      <Box className=' d-flex justify-content-center'>
        <Grid className='d-flex align-items-center gap-2' container md='10' xs='12'>
        <BackButton to={'/tn/samples'}/>
        <span className='mb-0 fw-bold'>
        Generating Report:
        </span>
        </Grid>
      </Box>
      <Box className=' py-2 d-flex justify-content-center'>
            <Grid className='' container md='9' xs='12'>
                {/* <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                <TextField fullWidth   id="outlined-basic" label="Pet's Name" variant="outlined" />
                </Grid>
                <Grid item md={4} xs={12}>
                <TextField fullWidth   id="outlined-basic" label="Age(years)" variant="outlined" />
                </Grid>
                <Grid item md={4} xs={12}>
                <TextField fullWidth   id="outlined-basic" label="Sex" variant="outlined" />
                </Grid>
                <Grid item md={4} xs={12}>
                <TextField fullWidth   id="outlined-basic" label="Species" variant="outlined" />
                </Grid>
                <Grid item md={4} xs={12}>
                <TextField fullWidth   id="outlined-basic" label="Pet Current Address" variant="outlined" />
                </Grid>
              </Grid> */}

            

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Date:</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <TextField type='date'
              required
              value={date}
              onChange={(e)=>setDate(e.target.value)}
              className='text-center' fullWidth   id="outlined-basic" variant="outlined" />
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Pet's Name:</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <p className=' text-center w-100 p-1 border_2 mb-0 fs_14px'>
              {clData?.pet?.pet_name}
              </p>
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Age (years):</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <p className='text-center w-100 p-1 border_2 mb-0 fs_14px'>
                {clData?.pet?.age}
              </p>
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Sex:</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <p className='text-center w-100 p-1 border_2 mb-0 fs_14px'>
                {clData?.pet?.gender}
              </p>
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Species:</p>
              </Grid>
              <Grid className='fs_14px' container md='3' xs='12'>
              <p className=' d-flex justify-content-center border_2 gap-4 w-100   mb-0'>
                {/* <span className='border '>
                Dog 
                </span> */}
                {/* <span className='border_left_2 border_right px-4'> </span> */}
                {clData?.pet?.type}
              </p>
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Parents Name:</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <p className='text-center w-100 p-1 border_2 mb-0'>
                {clData.firstname+" "+clData.lastname}
              </p>
              </Grid>
              </Grid>

              <Grid container>
              <Grid className='' container md='2' xs='12'>
              <p className='w-100 p-1 border_2 mb-0 fs_14px'>Pet's Current Address:</p>
              </Grid>
              <Grid className='' container md='3' xs='12'>
              <p className='text-center w-100 p-1 border_2 mb-0'>
              {clData.address}
              </p>
              </Grid>
              </Grid>
            </Grid>
      </Box>

          <Box className=' d-flex justify-content-center py-2'>
          <Grid className=' d-flex justify-content-center' container md='9' xs='12'>
          <div className='row'>
            { load ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
            gridData?.map((item) => (
              <div key={item.id} className="col-3">
                <div className='d-flex '>
                 {!isGreenCategory(item.category)&& <p className='border p-2' style={{ color: 'yellow', backgroundColor: '#3E4755' }}>M</p>}
                  <p className='border p-2' style={{ color: isGreenCategory(item.category)?"green":'red', backgroundColor: '#3E4755' }}>H</p>
                  <p className='text-wrap py-2 px-5 fs_10px white_space text-white' style={{ marginLeft: "2px", backgroundColor: '#3E4755' }}>{item.category}</p>
                </div>
                {item.items.map((dataItem, key) => (
                  <div className='d-flex align-items-center border'  key={key}>
                    <ReportItem selected={selectd} setSelected={setSelected} handleChange={handleChange} report={report} setReport={setReport} cat={item} item={dataItem.name} id={dataItem._id} />
                  </div>
                ))}
                
                <div>
               {item.category === "Fish" && (
                  <div className='mt-2  '>
                {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px mb-0'>
                    *Includes: Oils 
                    </p>}
                    {findInGridDataByName(item.items,"**")  &&  <p className='fs_10px mb-0'>
                    **Includes: Clam, Crab, Lobster, Oysters, Shrimp
                    </p>}
                  </div>
                )}
        {item.category === "Dust" && (
          <div className="mt-2">
             {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px mb-0'>
                    *Flock is used to make a variety of objects, from carpets, clothing apparel, and jewel boxes to paper goods (such as picture books, posters and greeting cards) that have raised or rough threads. Flocking is a fiber coating proce ss that can add dimension and texture to almost any article.
              </p>}
              {findInGridDataByName(item.items,"***")  &&   <p className='fs_10px mb-0'>
                    ***Flock Dust - Dust from Carpet Fibers
                    </p>}
            {/* <p className="fs_10px mb-0">
              **Includes: Clam, Crab, Lobster, Oysters, Shrimp
            </p> */}
          </div>
        )}
         {item.category === "Milks/Dairy" && (
                  <div className='mt-2  '>
                     {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px mb-0'>
                *Includes: Water & Milk
                    </p>}
                    {findInGridDataByName(item.items,"**")  &&  <p className='fs_10px mb-0'>
              **Includes: Pasteurized Products
                    </p>}
                  </div>
                )}
                    {item.category === "Nuts & Seeds" && (
                  <div className='mt-2  '>
                     {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px mb-0'>
                *Includes: milks/oils/waters
                    </p>}
                  
                  </div>
                )}
                    {item.category === "Legumes" && (
                  <div className='mt-2  '>
                     {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px mb-0'>
                *Includes: butter/oils
                    </p>}
                  
                  </div>
                )}

{item.category === "Spices & Herbs" && (
                  <div className='mt-2  '>
                    {findInGridDataByName(item.items,"*")  &&   <p className='fs_10px mb-0'>
                    *Includes: Oils, Butter, Water & Milk
                    </p>}
                    {findInGridDataByName(item.items,"**")  &&  <p className='fs_10px mb-0'>
                    **Includes: Oils & Butter
                    </p>}
                  </div>
                )}
         {item.category === "Petro Chemicals" && (
                  <div className='mt-2   mb-2'>
                     {findInGridDataByName(item.items,"*")  &&  <p className='fs_10px '>
                    *Extracting edible Oils from Seeds & Vegetables; Also an additive in gasoline, glue, varnish, ink
                    </p>}
                    {findInGridDataByName(item.items,"**")  &&  <p className='fs_10px mb-0 '>
                    **Additive in foods to absorb extra water and maintain moisture. Ex. Sea
                    soning blends, dried soups, salad dressing, baking mixes, powdered drink
                    mixes
                    </p>}
                  </div>
                )}
         {item.category === "Environmental Chemicals" && (
                  <div className='mt-2   mb-2'>
                     {findInGridDataByName(item.items,"**")  &&  <p className='fs_10px '>
                     **Toluene used most common in paint thinners
                    </p>}
             
                  </div>
                )}

{item.category === "Pollens" && (
                  <div className='mt-2  '>
                    {findInGridDataByName(item.items,"*")  &&   <p className='fs_10px mb-0'>
                    *Found in crude oil, paint thinners, paint brush cleaners, nail polish, glues, stain removers
                    </p>}
                </div>
                )}
                 {item.category === "Misc." && (
                  <div className='mt-2  '>
                    {findInGridDataByName(item.items,"****")  &&   <p className='fs_10px mb-0'>
                    ****Electronics Cell Phones/Portable Phones,Computers,Home Theaters,Microwaves,Stereo Systems,Televisions,etc.
                    </p>}
                </div>
                )}
               </div>
              
              </div>
            )))}
          </div>
          </Grid>
        </Box>

  <Box className=' py-2 d-flex justify-content-center' >
  <EnergeticImbalance selectedText={selectedText} handleTextClick={handleTextClick}/>
</Box>

      <Box className='d-flex justify-content-center py-2'>
   
        <Button type='submit'  className=' px-4 btn btn_bg_secondery' variant='contained' sx={{borderRadius:'20px'}}>
          Next
        </Button>
       
      </Box>

      </form>
    </div>
  )
}


export default Reports



const ReportItem = ({selected,handleChange,cat,item,id}) => {
  // const [selected1,setSelected1]=useState()
// console.log(item,"asdfghj")
  let selctedItem=selected.find(x=>x.id==id)
  return (
    <div className=' d-flex align-items-center justify-content-between '>
      <div className='d-flex'>
     {!isGreenCategory(cat.category)&& <Checkbox checked={selctedItem?.senstivity=="MD"}  onChange={()=>{
        handleChange(id,item,"MD")
        //  setSelected1("MD");
        }} style={{ margin: 0, padding: 0 }}/>  }       
      <Checkbox checked={selctedItem?.senstivity=="HG"} onChange={()=>{
        handleChange(id,item,"HG")
        //  setSelected1("HG")
        }} style={{ margin: 0, padding: 0 }}/>
      </div>
      <p className='fs_10px  px-2 mb-0 ' style={{color:isGreenCategory(cat.category)?"black":"" ,backgroundColor:selctedItem?.senstivity=="HG"  ? (isGreenCategory(cat.category)?"green":'red') : selctedItem?.senstivity =="MD" ? 'yellow' : '' }}>
        {item}
      </p>  
      
      </div>
  );
}

const EnergeticImbalance = ({handleTextClick,selectedText}) => {
// console.log(selectedText, "selectedText")
// console.log(handleTextClick, "handleTextClick")
  const data={
  items:[
    "Bacteria",
    "Chlorine",
    "Fungus",
    "Chloride",
    "Pesticide",
    "Inflammation",
    "Mold",
    "Pain",
    "Parasites",
    "Virus",
    "Yeast",
    "Candida",
    "Herbicides",
    "Emotions",
    "Vaccinosis",
    "Thyroid"
  ]
  } 
    return (
      <Grid className=" bg-white  py-2 w-100" container md={8} xs={12} >
  <Box className=" shadow p-1  text-center text-white w-100" sx={{ backgroundColor: "#3E4755  " }}>
  ENERGETIC IMBALANCES
    </Box>
  <Box className="w-100 shadow p-3 mb-5 bg-body rounded" >
    <p className="fw-bold fs_14px text-center">
    Highlighted areas reference imbalances that may require help to eliminate..
    </p>
    <Box className=" d-flex justify-content-center" style={{paddingBottom:selectedText.includes('Immune System') ?50:0}}>
      
    <Grid className={` ${
                selectedText.includes("Immune System") ? "energetic-highlight" : ""
              }`} container md={6} xs={12}>
    <p
          className={`clickable-wrapper text-center w-100 mb-0 ${selectedText.includes('Immune System') ? 'selected' : 'text-white'}`}
        style={{
  backgroundColor: '#3E4755',
  cursor: 'pointer'
}}

          onClick={() => handleTextClick('Immune System')}
        >
          Immune System
        </p>  



      
   {data.items.map((item,index)=>(  
     <Grid md={6} xs={12}> <div
      onClick={(event) => handleTextClick(item)}
      className={`clickable-wrapper ${selectedText.includes(item) ? 'selected' : 'text-white'}`}

    >
      <p
        className={`text-center mb-0 `}
        style={{
          backgroundColor: index % 4 >= 2 ? '#828383' : '#D1A68F',
          cursor: 'pointer',
          fontWeight: selectedText.includes(item) ? 'bold' : 'normal'
        }}
        
      >
        {item}
      </p>
    </div>
      </Grid>
    ))}





    
    </Grid>

    </Box>
    </Box>
  </Grid>
    );
};
export const isGreenCategory=values=>["May Also Be Helpful:","Cell Salts:"].includes(values)