import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link, NavLink, Outlet } from 'react-router-dom';
import Footer from './Footer';
import { useLocation } from 'react-router-dom'; 
import { Avatar } from '@mui/material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/counterSlice';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';
import MuiDialog from './mui/MuiDialog';
import { useState } from 'react';
import apiClient from '../api/apiClient';

const drawerWidth = 240;

function Navbar (props){ 
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navItems=[]
  const { window,type } = props;
  const {isLoggedIn,userType} = useSelector((state) => state.auth)
  // redux 
const dispatch = useDispatch();
const navigate = useNavigate();
const [show,setShow]=useState(false)
const handleLogout = () => {
  dispatch(logout());
  navigate('/');
};

const [refresh,setRefresh]=useState(true)
const [user,setUser]=useState()
const fetchProfile=async()=>{
  const result=await apiClient.get("/auth/user-info")
  setUser(result)
  // console.log(result,"oip");
  setRefresh(false)
}


const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  useEffect(()=>{
    refresh &&fetchProfile()
  },[refresh])
  useEffect(() => {
    
    if(isLoggedIn)
    navigation(userType)
    }, [])
  const navigation=(role)=>{
    if(role==="AD")
    {
      !location.pathname.includes("admin")&&navigate('/admin/dashboard');
    }
    if(role==="SA")
    {
      !location.pathname.includes("admin")&&navigate('/admin/dashboard');
    }
    else
    if(["PPTN","TN"].includes(role))
    {
      !location.pathname.includes("tn")&&navigate('/tn/dashboard');
    }
    
  }
  if(type=="client"){
    navItems = [
      { label: 'Dashboard', link: '/tn/dashboard' },
      { label: 'Barcode', link: '/tn/barcode' },
      { label: 'Samples', link: '/tn/samples' },
      { label: 'Reports Submitted', link: '/tn/report-submitted'},   
      // { label: 'Sample Received', link: '/tn/rec-samples'},
    ];
  }else
   navItems = [
    { label: 'Dashboard', link: '/admin/dashboard' },
    { label: 'Barcode', link: '/admin/barcode' },
    { label: 'Client', link: '/admin/client' },
    ...(userType === "AD" ? [{ label: 'Technicians', link: '/admin/technicians' }] : []),
    ...(userType === "AD" ? [{ label: 'Sub-Admin', link: '/admin/sub-admin' }] : []),
    // { label: 'Samples', link: '/admin/samples' },
    ...(userType === "AD" ? [{ label: 'Reports Data', link: '/admin/reports' }] : []),
    { label: 'Submitted Reports', link: '/admin/sub-reports' },
  ];
  
  

  const drawer = (
    <Box className=" h-100" onClick={handleDrawerToggle} sx={{ textAlign: 'center' , backgroundColor:"#3E4755"}}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <Box className="d-flex align-item-center px-1 py-2">
      <div className='d-xs-block d-sm-none ' style={{width:"9rem"}}>
          <img width={'100%'} src={require("../assets/logo-new.png")} alt="" />
          </div>
      </Box>
      <Divider />
      <List>
        {navItems.map((value, i) => (
       <ListItem
       className=''
       key={i}
       component={NavLink}
       to={value.link}
     
       sx={{
        backgroundColor:"#3E4755",
        color: "#ffff",
        "&.active": { backgroundColor: "#D2A78F", color: "#ffff",
        "& .myIconClass, & .MuiTypography-root": {
          color: "#ffff"
        } }
      }}
     >
       <ListItemButton
         className="list-item "
         sx={{
           "&:hover": {
             color: "#ffff",
             "& .myIconClass, & .MuiTypography-root": {
               color: "#ffff"
             }
           }
         }}
       >
         <ListItemText
           primary={
             <Typography className='' variant="body2" style={{ fontSize: 14 }}>
               {value.label}
             </Typography>
           }
         />
       </ListItemButton>
     </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
   
  return ( 
    <>
    <Box className="" >
      {/* <CssBaseline /> */}
      
      <AppBar className=' ' component="nav" 
      sx={{
        backgroundColor: "#3E4755",
        width: {
          position: 'static',
          xs: '100%',
          md: '96%',
        },
      margin:{
        xs: 0,
        md: 3,
      },
       borderRadius:"20px"

      }}>
        <Toolbar>
         <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            MUI
          </Typography> */}
          <div className='d-none d-sm-block' style={{width:"11rem"}}>
              <img width={'100%'} src={require("../assets/logo-new.png")} alt="" />
          </div>
          
          <Box className=" w-100 px-5"  sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
            {navItems.map((item) => (
              <NavLink className="" to={item.link} style={{ textDecoration: 'none', }}>
              <Button  
              style={{fontSize:"11px"}}
              className='white_space '
                  variant='contained' 
                  key={item} 
                  startIcon={item.label === 'Dashboard' ? <HomeIcon /> : null}
                  sx={{
                    color: location.pathname === item.link ? '#000' : '#fff',
                    backgroundColor: location.pathname === item.link ? '#fff' : '#3E4755',
                    '&:hover': {
                      backgroundColor: location.pathname === item.link ? '#313942' : '#3E4755',
                    },
                    // boxShadow: 'none',
                  }}
              >
                  {item.label}
              </Button>
          </NavLink>
          
            ))}
            <Box className=" d-flex justify-content-end w-100 align-items-center gap-2 ">
            
            <PowerSettingsNewIcon className='me-4' onClick={()=>setShow(true)} sx={{ color: "#fff" }} />

              <Link className='' to={type!=="client"?'/admin/profile':"/tn/profile"}>
                <Avatar alt="Remy Sharp" src={user?.data.profile} />
              </Link>
            </Box>
            <MuiDialog 
            
            // title={"Logout"}
            title={"Are you Sure want to Logout?"} 
            show={show}
            Buttons={
              ()=>(<>
              
                <Button onClick={() => setShow(false)}>Cancel</Button>
                <Button onClick={()=>dispatch(logout())} color="error" variant="contained">
                  Logout
                </Button>
              </>
              )
            }
            />
          </Box>
        </Toolbar>
      </AppBar>
     
      <Box component="nav" >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      
    </Box>
    <Box className='' sx={{marginTop:8, minHeight:'100vh'}}>
    <Outlet context={{type,setRefresh,user}} />
    </Box>
    <div className='mt-4'>
      <Footer/>
    </div>
    </>
  );
}

export default Navbar;