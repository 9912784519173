import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Bootstrap Bundle icon
import "bootstrap-icons/font/bootstrap-icons.css";
// Bootstrap Bundle css
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Provider } from 'react-redux';

import {store} from './redux/store'
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './modules/ScrollToTop';
import { ToastContainer } from 'react-toastify';
import "rsuite/dist/rsuite.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <Provider store={store}>

    <BrowserRouter>
<ScrollToTop/>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>

    <App />
    
    </BrowserRouter>
  </Provider>
);
reportWebVitals();
