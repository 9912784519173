import React from 'react'
import { Box, Button, Container, Grid, IconButton, InputBase, Paper, Typography } from '@mui/material'
import {Helmet} from "react-helmet";
import SampelRecTab from '../../components/SampelRecTab';

const ClientRecSample = () => {
  return (
    <div>
      <Helmet>
        <title>
          Samples
        </title>
      </Helmet>
      <Container className='' maxWidth='lg'>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
              <h3 className='mb-0'>
              Samples:
              </h3>
            </div>
          </Grid>
    </Grid>
    
    <Grid >
      <SampelRecTab/>
    </Grid>
      </Container>
    </div>
  )
}

export default ClientRecSample