import React, { useEffect, useState } from "react";
import Webnav from "./Webnav";
import {
  Box,
  Button,
  Grid,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";
import { handleErrors } from "../modules/handleErrors";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../redux/modalSlice";
import { Modal } from "react-bootstrap";
import { copyRightYear } from "../modules/helpers";

function Home() {
  const [barcode, setBarcode] = useState();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleNumericInput = (e) => {
    const numericInput = e.target.value
    setBarcode(numericInput);
  };

  const handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    // Allow numbers and the backspace key (key code 8)
    if (charCode !== 8 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };
  const navigate = useNavigate();
const {isLoggedIn,userType} = useSelector((state) => state.auth)
useEffect(() => {
if(isLoggedIn)
navigate(userType=="AD"?"/admin/dashboard":"/tn/dashboard")
}, [])
  async function handleNavigate() {
    const result = await apiClient.get(`/report/${barcode}/?validate=true`);
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Report",
          message: result.data.message,
        })
      );
    navigate("/get-report/" + barcode);
  }
  async function handleNavigateClient() {
    const result = await apiClient.post(`/client/?validate=true`, { barcode });
    if (!result.ok)
      return dispatch(
        showModal({
          error: true,
          title: "Client",
          message: result.data.message,
        })
      );
    navigate("/petbio-registration/" + barcode);
  }

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#FFFFFF",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    setShow(true);
  }, []);
  return (
    <div>
      <Webnav />
      <InfoModal show={show} onHide={() => setShow(false)} />

      <Grid container spacing={0}>
        <Grid
          className=" "
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#716879",
            padding: "2rem",
            display: "flex",
            alignItems: "",
            justifyContent: "center",
            marginTop: 10,
          }}
        >
          <div className="">
            {/* <Typography className='nova_font_bold text-uppercase' variant="h4" align="left">
      Welcome to a world where <br /> your pet's Intolerances are <br /> taken seriously
    </Typography> */}
            {/* <Typography className='nova_font_normal' component="p" align="left" sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
      Simply click "register my test" to fill out all of your pets info. Be sure to add your barcode number that can be found on the card in your box. Once you have registered your test and sent in your samples, please give us up to 7 business days to send your results. You will receive an email as soon as your samples arrive at our biofeedback center. Once your results are in, you will receive an email with your results and our suggestions on solutions. We are so happy you are here and taking this step in finding out what your pet is trying to tell you.
    </Typography> */}
                 {/* <p>
             To get started,
              </p> 
              
            <Typography
              className="nova_font_normal "
              component="p"
              align="left"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
            
               simply click on the "Register My Test" button and
              fill out all of your pet's information. 
              Don't forget to include the barcode number which you can find on the card inside your box.
            </Typography>

            <Typography
              className="nova_font_normal "
              component="p"
              align="left"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              After registering, please send in your pet's samples.
              After registering, please send in your pet's samples. 
              As soon as they arrive at our biofeedback centre. */}
               {/* As soon as
              they arrive at our biofeedback centre, you will receive an email
              notification. */}
         {/* </Typography> */}
            {/* <Typography
              className="nova_font_normal "
              component="p"
              align="left"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              Please be patient and allow up to 5 to 7 business days to receive your
              results via email. This email will guide you through understanding
              the results and direct you to receive product recommendations from
              our knowledgeable team members.
            </Typography>
            <Typography
              className="nova_font_normal "
              component="p"
              align="left"
              sx={{ marginTop: "1rem", marginBottom: "1rem" }}
            >
              We are so happy you are here and taking this step towards finding
              out what your pet is trying to tell you.
            </Typography> */}

  <h6>  TO GET STARTED:   </h6>


  <p className="my-3"> 1. Find your barcode number on the card inside your box.</p>
<p  className="my-3">  2. Click on "Enter Your Code" and add your specific barcode number. </p>
<p  className="my-3">  3. Click on the "Register My Test" button and fill out your pet's information. </p>

<p> After registering, please return your pet's samples in the self-addressed prepaid shipping bag. <span className="fw-bold"> International tests: please see below. </span>  As soon as they arrive at our biofeedback center,


 we will send you an email with a confirmation of receipt. Please be patient and allow 7–10 business days to receive your results via email after we have received them. This email will guide you through understanding the results and direct you to receive product recommendations from our knowledgeable team members. If you have any questions or concerns about the process, please don't hesitate to reach out to our customer support team. They are available to assist you and provide any necessary guidance throughout the entire testing process.
 </p>

 <p className="mb-5 ">

 We value your trust in our services and look forward to helping you gain valuable insights into your pet's health and wellness.
 </p>


<u>
  <h6 className="mb-3">
  International:
  </h6>
  
  </u>
<p>Using Post Mail, send to:</p>

<p>PO Box 6765, Round Rock, TX 78683, USA</p>
<p className="m-0">Using FedEx, UPS, or DHL, send to:</p>
<p className="m-0">2250 Double Creek Dr., #6765, Round Rock, TX 78664, USA </p>

            <Box
              sx={{
                backgroundColor: "#3E4755",
                padding: "1rem",
                flexWrap: "wrap",
                borderRadius: "8px",
                width: "100%",
                marginTop: "2rem",
                marginBottom: "2rem",
                //       "& md":{
                // justifyContent:"start"
                //       }
              }}
              className=" row justify-content-start align-items-center gy-2 gap-2"
            >
              <div className="col-xl-4 col-12">
                <ThemeProvider theme={theme}>
                  <TextField
                    id="barcode"
                    variant="outlined"
                    value={barcode}
                    size="small"
                    fullWidth
                    onChange={handleNumericInput}
                    // onKeyPress={handleKeyPress}
                    // inputMode="numeric"
                    // type="number"
                    // onChange={(e)=>setBarcode(e.target.value)}
                    InputLabelProps={{
                      style: { color: "#FFFFFF" },
                    }}
                    InputProps={{
                      style: {
                        color: "#FFFFFF",
                      },
                    }}
                    label="Enter Your Code"
                  />
                </ThemeProvider>
              </div>
              <div className="col-xl-4 col-12">
                <div>
                  <Link
                    className="white_space decorartion-none"
                    onClick={handleNavigateClient}
                    state={{ data: barcode }}
                  >
                    <Button
                      className="py-2 btn btn_bg_secondery"
                      variant="contained"
                    >
                      Register Test
                    </Button>
                  </Link>
                </div>
                {/* <div>
                  <Link
                    className="white_space decorartion-none"
                    onClick={handleNavigate}
                  >
                    <Button
                      className="py-2 btn btn_bg_secondery "
                      variant="contained"
                    >
                      Get Report
                    </Button>
                  </Link>
                </div> */}
              </div>
            </Box>
          </div>
        </Grid>

        <Grid className="" item xs={12} md={7}>
          <Box className="">
            <img className="img-fluid"
              src={require("../assets/dogi-style-2.png")}
              alt=""
              style={{ maxWidth: "100%", height: "auto", objectFit: "cover" }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          backgroundColor: "#3E4755",
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 1rem",
          "@media (max-width: 600px)": {
            height: "auto",
          },
        }}
      >
        <Typography
          className="nova_font_bold"
          variant="h4"
          color="white"
          align="center"
        >
          GLACIER PEAK HOLISTICS <br /> TEST REGISTRATION
        </Typography>
      </Box>
      <Box
        className="shadow"
        sx={{
          backgroundColor: "#3E4755",
          padding: "1rem",
          textAlign: "center",
          color: "white",
        }}
      >
         <p className='text-center text-white mb-4' style={{backgroundColor:"#3E4755"}}>
          &copy;{copyRightYear()} GLACIER PEAK HOLISTICS
              TEST REGISTRATION
            All Rights Reserved.
        </p>

        <Typography className="text-muted" variant="body2" color="inherit">
          All rights reserved  Maintained & Developed by 
          <span> </span>
        <a href="https://fabtechsol.com" target="_blank" rel="noopener noreferrer">
          fabtechsol
        </a>
      </Typography>
      </Box>
    </div>
  );
}
export default Home;

function InfoModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className=" " closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100"
        >
          INFORMATION
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <div>
        <h2>HOW TO GATHER SAMPLES:</h2>
        <ol>
            <li>
                Carefully remove the swabs from the biohazard bag, only touching one end.
            </li>
            <li>
                Swab your pet's mouth using all three at once or one at a time.
            </li>
            <li>
                Carefully put them back in the biohazard bag with the wet end at the bottom.
            </li>
            <li>
                Add hair sample to bag with swabs. 
                <ul>
                    <li>
                        Options to collect hair: (The size of a quarter will do)
                        <ul>
                            <li>Use a clean, unused brush or comb (it’s important that the hair should not be contaminated by any other pet or human).</li>
                            <li>Gently pull out a small amount of shedding hair.</li>
                            <li>Cut hair from the rump area.</li>
                            <li>For hairless pets, make sure to get the swabs very wet with the saliva from your pet's mouth. We can still run the test this way.</li>
                            <li>Put the hair in the biohazard bag with the swabs and close it using the zip closure on the end.</li>
                        </ul>
                    </li>
                </ul>
            </li>
            <li>
                Place it in the bag with the return label already attached, and drop it off at your nearest post office.
            </li>
            <li>
            <h6>FOR INTERNATIONAL RETURNS:</h6>
            <ul style={{listStyle:"lower-alpha"}}>
            <li>Follow the same procedure as above to collect your samples.</li>
            <li>Post Mail: PO Box 6765, Round Rock, TX 78683.</li>
            </ul>
            </li>
            <li>
            If using FedEx, UPS, or DHL, please ship to the physical address:
              <ul>
                <li style={{listStyle:"lower-alpha"}}>
                2250 Double Creek Dr, c/o Box 6765, Round Rock, TX 78664, USA.
                </li>
              </ul>
            </li>
        </ol>
    </div>
</Modal.Body>

    </Modal>
  );
}
