import './App.css';
import { Route, Routes,BrowserRouter } from "react-router-dom";
import {AddnewClient, AddnewTechnicians, Barcode, Client, Dashboard, Login, Navbar, NotFound, PetBio, Samples, Technicians} from './Pages';
import ClientSamples from './Pages/client/ClientSamples';
import ClientPetBio from './Pages/client/ClientPetBio';
import ClientDashboard from './Pages/client/ClientDashboard';
import Home from './Webpages/Home';
import PetBioRegistration from './Webpages/PetBioRegistration';
import Reports from './Pages/client/Reports';
import PetSubmitReport from './Pages/client/PetSubmitReport';
import { GetReport } from './Webpages/GetReport';
import { GetReport_2 } from './Webpages/GetReport_2';
import ProtectedRoute from './components/ProtectedRoute';
import TestReport from './Webpages/TestReport';
import useNotOnPages from './hooks/useNotOnPages';
import { useDispatch, useSelector } from 'react-redux';
import ToastModal from './components/ToastModal';
import { hideModal } from './redux/modalSlice';
import Profile from './Pages/Profile';
import { AdminReports } from './Pages/AdminReports';
import SubAdmin from './Pages/SubAdmin';
import AddnewSubadmin from './Pages/AddnewSubadmin';
import ClientRecSample from './Pages/client/ClientRecSampel';
import SubmittedReport from './Pages/SubmittedReport';
import ClientSubmittedReport from './Pages/client/ClientSubmittedReport';
import ArchiveBarcode from './Pages/ArchiveBarcode';
import { ThemeProvider, createTheme } from '@mui/material';
import TestBarcode from './Pages/TestBarcodes';


function App() {
  // useNotOnPages(["/report/","/final-report/"])
  const dispatch = useDispatch();
  const {modal,auth:{userType}} = useSelector(state=>state);

  const handleClose = () => {
    dispatch(hideModal());
  };
  const theme = createTheme({

    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: { color: "red" },
            },
        },
        MuiFormControlLabel: {
          styleOverrides: {
              asterisk: { color: "red" },
          },
      },
    },

})
  return (
    <ThemeProvider theme={theme}>  
  <ToastModal 
        values={modal}
        onHide={handleClose}
      />
 <Routes>
  <Route path='/login' element={<Login/>} />
  <Route path='/test' element={<TestBarcode/>} />
  <Route index element={<Home/>} />
  <Route element={ <ProtectedRoute ><Navbar /> </ProtectedRoute>}>
      <Route path='/admin/dashboard' element={<Dashboard />} />
      <Route path='/admin/barcode' element={<Barcode />} />
      <Route path='/admin/client' element={<Client />} />
      <Route path='/admin/samples' element={<Samples />} />
    { userType==="AD"&& <Route path='/admin/technicians' element={<Technicians />} />}
    { userType==="AD" && <Route path='/admin/sub-admin' element={<SubAdmin/>}  />}
    <Route path='/admin/sub-admin/addnew-subadmin' element={<AddnewSubadmin/>} />
      <Route path='/admin/technicians/addnew-technicians' element={<AddnewTechnicians />} />
      <Route path='/admin/pet-bio/:id' element={<PetBio />} />
      <Route path='/admin/addnew-client' element={<AddnewClient />} />
      <Route path='/admin/profile' element={<Profile role={"AD"}/>} />
      <Route path='/admin/reports' element={<AdminReports/>} />
      <Route path='/admin/sub-reports' element={<SubmittedReport/>} />
  </Route>

  <Route element={ <ProtectedRoute > <Navbar type={"client"} /> </ProtectedRoute>}>
   
      <Route path='/tn/dashboard' element={<ClientDashboard />} />
      <Route path='/tn/profile' element={<Profile />} />
      <Route path='/tn/barcode' element={<ArchiveBarcode />} />
      <Route path='/tn/samples' element={<ClientSamples />} />
      <Route path='/tn/rec-samples' element={<ClientRecSample/>}/>
      <Route path='/tn/report-submitted' element={<ClientSubmittedReport/>} />
      <Route path='/tn/pet-bio/:id' element={<ClientPetBio />} />
      <Route path='/tn/report/:barcode' element={<Reports />} />
      <Route path='/tn/final-report/:barcode' element={<GetReport_2 />} />
    
  </Route>

  <Route>
    <Route path='/get-report/:barcode' element={<GetReport />} />
    <Route path='/test-report/:barcode' element={<TestReport/>}/>
    
    <Route path='/petbio-registration/:id' element={<PetBioRegistration />} />
    <Route path='*' element={<NotFound />} />
  </Route>
</Routes>

</ThemeProvider>
  );
}

export default App;
