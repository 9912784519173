import { AccountCircle, Padding, Search } from '@mui/icons-material'
import { Box, Button, Container, Grid, IconButton, InputBase, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import HelpIcon from '@mui/icons-material/Help';
import { useState } from 'react';
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminButton from '../components/mui/AdminButton';
import CircularProgress from '@mui/material/CircularProgress';
import {Helmet} from "react-helmet";
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/modalSlice';
import SelectOption from '../components/mui/SelectOption';
import EditIcon from '@mui/icons-material/Edit';
function Technicians () {
  const [searchQuery, setSearchQuery] = useState('');
  const [load, setLoad]= useState(false);
  const [ShowAssign, setShowAssign]= useState(false);
  const [searchName, setSearchName] = useState("");
  const [role, setRole] = useState("");
  const [tnData, setTnData] = useState();
  const [modalShow, setModalShow] =useState(false);

const [data,setData]=useState([])
const [id,setId]=useState()
const fetchdata = async () => {
  setLoad(true);
  const endpoint = `/auth/all-users-t?name=${searchName}&role=${role}` ;
  const result = await apiClient.get(endpoint);
  setLoad(false);
  if (!result.ok) {
    toast.error('Error');
  } else {
    setData(result.data.results);
  }
};


useEffect(() => {
  fetchdata()
}, [role])





  return (
    <div>
      <Helmet>
        <title>
        Technicians
        </title>
      </Helmet>
      <Container className='' maxWidth='lg'>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
              <h3 className='mb-0'>
              Technicians List:
              </h3>
              <div className='d-flex justify-content-between align-items-center px-4 col-md-6' style={{ backgroundColor: "#f1f1f5", borderRadius: "50px" }}>
                  <InputBase
                     className=''
                     sx={{
                       ml: 1,
                       flex: 1,
                       px: 1,
                     }}
                     placeholder="Search by name..."
                     type="search"
                     value={searchName}
                     onChange={(e) => setSearchName(e.target.value)}
                   />
              <Box sx={{ width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1 }}>
  <IconButton
    type="button"
    sx={{
      p: '5px',
      backgroundColor: '#3E4755',
      color: 'white',
      '&:hover': {
        backgroundColor: '#3E4755',
        color: 'white',
      },
    }}
    aria-label="search"
    onClick={() => fetchdata()}
  >
    <Search style={{ fontSize: 25 }} />
  </IconButton>
</Box>
            </div>
              <Link className='mt-3 mt-sm-0' to={'/admin/technicians/addnew-technicians'}>
              <Button sx={{borderRadius:20, whiteSpace: 'nowrap'}} className='btn_bg_secondery' variant='contained' >
                Add new Technicians
              </Button>
              </Link>
            </div>
          </Grid>
    </Grid>

    <Grid className='d-flex justify-content-center ' container>
  
      <Grid className=' d-flex justify-content-center' container md={10} xs={12} spacing={2} component={Paper} elevation={5} sx={{ marginTop: 5, padding: 5, borderRadius:6 }}>
      <Grid  container style={{justifyContent:"end"}}>

      <Grid item md={4} xs={12}>
      <SelectOption style={{width:"100%"}} 
       data={[{value:"TN",label:"Normal"},
      {value:"PPTN",label:"PP Technician"},
      {value:"Exp",label:"Exp Technician"}
    ]} 
       id="outlined-basic" value={role} onChange={(e)=>setRole(e.target.value)}
        label="Technician Type" variant="outlined" />
      </Grid>
      </Grid>
      {load?(
        <Box display="flex" justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
      ):(
      <Grid className='' item md={11} xs={11} sx={{height:"100vh", overflowY:" auto " }} >
        {data?.map((technician, index) => (
        <Grid className=''
          key={index}
          item
          xs={11}
          md={11}
          sx={{
            backgroundColor: '#F1F1F5',
            // color:'white',
            borderRadius: 3,
            p: 1.5,
            // display: 'flex',
            // alignItems: 'center',
            my: 2, // Add margin top and bottom
            flexWrap:'wrap',
          }}
        >
          <div className=' row d-flex justify-content-between flex-wrap'>
          <div className=' d-flex col-md-5'>
          <AccountCircleIcon fontSize='large' />
          <Box sx={{ ml: 2 }}>
            <Typography variant="body1">{technician.name}</Typography>
            <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>
              {technician.email}
            </Typography>
          </Box>
          </div>
          <div className=' col-md-3'>
            <LocalPhoneIcon />
            <Typography variant="body2" component="span" sx={{ display: 'inline', ml: 1 }}>
              {technician.contact}
            </Typography>
          </div>

          <div className='d-flex justify-content-end gap-2 align-item-center col-md-4'>
            <EditIcon
            onClick={() => {
              setTnData(technician)
              setModalShow(true)}}
            sx={{
              cursor:"pointer",
                fontSize: '40px', // Adjust the size as needed
                backgroundColor: '#7C828C', // Specify the desired background color
                borderRadius: '50%', // Make it circular by setting the border-radius to 50%
                color:'white',
                padding: '8px', // Add padding to create some space around the icon
              }}/>
          <DeleteIcon onClick={()=>{
            setId(technician._id)
            setShowAssign(true)}}
              sx={{
                cursor:"pointer",
                fontSize: '40px', // Adjust the size as needed
                backgroundColor: '#7C828C', // Specify the desired background color
                borderRadius: '50%', // Make it circular by setting the border-radius to 50%
                color:'white',
                padding: '8px', // Add padding to create some space around the icon
              }}
            />
          </div>

          </div>
        </Grid>
      ))}
  {modalShow&& <EditTech
  fetchdata={fetchdata}
  tnData={tnData}
    show={modalShow}
    onHide={() => setModalShow(false)}
  />}
      <AssignModal
      fetchdata={fetchdata}
      id ={id}
      show= {ShowAssign}
      onHide={()=>{setShowAssign(false)}}
      />
      </Grid>)}
      
      {/* <Grid className='' item xs={1} md={1} sx={{backgroundColor:""}}  >
      <div>
      {Array.from({ length: 26 }, (_, index) => (
        <p style={{cursor:"pointer" }} className='mb-1' key={index}>{String.fromCharCode(65 + index)}</p>
      ))}
    </div>
      </Grid> */}
      </Grid>
    </Grid>


      </Container>
    </div>
  )
}
export default Technicians;

function AssignModal(props) {
  //console.log(props.id);
  const dispatch=useDispatch()
  const handleDelete=async()=>{
    const result=await apiClient.delete(`/auth/technician/${props.id}`)
    if(!result.ok) return     dispatch(showModal({
      error:true,
      title: 'Technician',
      message: result.data.message,
    }));
    props.fetchdata();
    dispatch(showModal({
      title: 'Deleted',
      message: 'Successfully deleted',
    }));
  }
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className=' ' closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
        <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#3E4755',
              p: '4px',
            }}
          >
  <DeleteIcon style={{ fontSize: '18px', color: 'white' }} />
    </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>Delete technician</h4>
        <p className='text-center'>
       Are you sure you want to delete <br /> this technician?
        </p>
       

      </Modal.Body>
      <Modal.Footer className='d-flex flex-column' >
          <Button onClick={()=>{
          handleDelete();
          props.onHide();
          }} sx={{borderRadius:"20px"}} fullWidth variant='contained' className='btn_bg_secondery'>
          Yes
        </Button>
    

        <Button sx={{borderRadius:"20px"}} 
        onClick={()=>{
          props.onHide()
        }}
         fullWidth variant='contained' className='mt-2 btn_bg_secondery'>
          No
        </Button>

    </Modal.Footer>
        </Modal>
  );
}

 function EditTech(props) {
  console.log(props.tnData);
  const initailState={
    name:props.tnData.name||"",
    email:props.tnData.email||"",
    contact:props.tnData.contact||"",
    password:'',
    confirm_password:"",
    role:props.tnData.role
  }
  
  const [load,setLoad]=useState(false)
  const [technician,setTectnician]=useState(initailState)
  const [message, setMessage] = useState({text:"",color:""});
  const dispatch = useDispatch()
  const handleChange=(key,value)=>{
    setTectnician({...technician,[key]:value})
  }
  const handleSubmit=async(e)=>{
    e.preventDefault()
    setLoad(true)
    if(technician.password!=technician.confirm_password){
      setLoad(false)
return setMessage({text:"Password doesn't match please enter again",color:"danger"})}else{
 
  resetErrors()
}
const result =await apiClient.put(`/auth/technician/${props.tnData._id}`,technician)
setLoad(false)
//console.log(result);
if(!result.ok)  return     dispatch(showModal({
  error:true,
  title: 'technician',
  message: result.data.message,
}));
props.fetchdata()
 props.onHide()
  }
  function resetErrors() {
    setMessage({text:"",color:""})
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div>
      <Container className='' maxWidth='lg'>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex align-items-center'>
          <div className='px-1 rounded' >
            {/* <Link to={'/admin/client'} style={{ color: '#000000' }}>
              <ArrowBackIosIcon />
            </Link> */}
           
          </div>
             
            </div>
          </Grid>
    </Grid>
    
    
<form onSubmit={handleSubmit}>
    <Grid md={12} xs={12}>
    <Grid container spacing={2}>
      
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='text'   id="outlined-basic" value={technician.name} onChange={(e)=>handleChange("name",e.target.value)} label=" Name" variant="outlined" />
      </Grid>
      {/* <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Last Name" variant="outlined" />
      </Grid> */}
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='email' disabled  id="outlined-basic" label="Email" value={technician.email} onChange={(e)=>handleChange("email",e.target.value)} variant="outlined" />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='number'  id="outlined-basic" label="Contact" value={technician.contact} onChange={(e)=>handleChange("contact",e.target.value)} variant="outlined" />
      </Grid>
    </Grid>
    <Grid sx={{py:2}} container spacing={2}>
      <Grid item md={4} xs={12}>
      <TextField fullWidth  type='password'   id="outlined-basic" value={technician.password} onChange={(e)=>handleChange("password",e.target.value)} label="Password" variant="outlined" />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth type='password' onFocus={resetErrors}  id="outlined-basic" value={technician.confirm_password} onChange={(e)=>handleChange("confirm_password",e.target.value)}label="Re-enter Password" variant="outlined" />
      <Grid item xs={12}>
        <p style={{color:'red'}}>{message.text}</p>
      </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
      <SelectOption style={{width:"100%"}} 
        data={[
          {value:"TN",label:"Normal"},
        {value:"PPTN",label:"PP Technician"},
        {value:"EXP",label:"Exp Technician"}
      ]}  
        id="outlined-basic" value={technician.role} onChange={(e)=>handleChange("role",e.target.value)} label="Technician Type" variant="outlined" />
      </Grid>
    </Grid>
    
    </Grid>
    <Grid sx={{py:2}}>
      {!load?
          <Button className='btn_bg_secondery ' type='submit' variant='contained'>
               Save
          </Button>:
          <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
            <CircularProgress />
          </Box>}
      
        
    </Grid>
    </form>
   
    
      </Container>
    </div>
        
      </Modal.Body>
      
    </Modal>
  );
}
