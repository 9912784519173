import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Webnav() {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  const {isLoggedIn}=useSelector(state=>state.auth)
  return (
    <Box sx={{ flexGrow: 1, position: 'sticky', top: 0, zIndex: 1 }}>
    <AppBar position="fixed" sx={{ backgroundColor: '#3E4755' }}>
      <Toolbar variant="dense" sx={{ minHeight: 84 }}>
        <div style={{ width: "8rem" }}>
          {/* <img width={'100%'} src={require("../assets/logo-new.png")} alt="" /> */}
        </div>
        <IconButton edge="start" color="inherit" aria-label="menu">
        </IconButton>
        <Box className=' w-100 d-flex justify-content-end'>
          {isLoginPage&&!isLoggedIn ?(
             <Link to={'/login'}>
        <button title='' className='btn btn_bg_secondery text-white px-4 py-3'>
          Login as Admin or Technician
        </button>
              </Link>
          ) : null}
        </Box>
        <Typography variant="h6" color="inherit" component="div">
         {/* kekcdl */}
        </Typography>
      </Toolbar>
    </AppBar>
  </Box>
  
    

  );
}