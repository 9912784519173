import { Search } from '@mui/icons-material'
import { Box, Container, Grid, IconButton, InputBase, Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Linechart } from '../../components/Linechart'
import ClientDashboardAnalytics from '../../components/ClientDashboardAnalytics'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { Icon } from '@mui/material';
import Card from '@mui/material/Card';
import apiClient from '../../api/apiClient';
import {Helmet} from "react-helmet";

function Dashboard () {
  const [sampleAss, setSampleAss] = useState(0);
  const [report, setReport] = useState(0);

  const fetchData = async () => {
    try {
      const [sampleAssResult, apiReport] = await Promise.all([
        apiClient.get('/sample/?sampleStatus=ASS'),
        apiClient.get('/report/user-reports/'),
      ]);
  
      if (sampleAssResult.ok && apiReport.ok) {
        // Filter the samples with sampleStatus "Ass"
       
        const sampleAssCount = sampleAssResult.data.results.length;
        const reportCount = apiReport.data.count;
  
        // Update the state with the retrieved data
        setSampleAss(sampleAssCount);
        setReport(reportCount);

        //console.log(sampleAssCount, "setSampleAss");
        //console.log(reportCount, "setReport");
      }
    } catch (error) {
      //console.log(error);
    }
  };
  

  useEffect(() => {
  fetchData( )
  }, [])



  return (
    <div className=''>
      <Helmet>
        <title>
          Dashboard
        </title>
      </Helmet>
     
    <Container className='' maxWidth='lg' style={{height:"80vh"}}>
 <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className=' d-flex justify-content-between align-items-center flex-column flex-sm-row'>
              <h3 className='mb-0'>
              Dashboard
              </h3>
            </div>
          </Grid>
    </Grid>
        <Grid className='d-flex justify-content-center ' container>
              <Grid  md={10} xs={12}  spacing={2} component={Paper} elevation={5} sx={{marginTop:5, padding:5}}>
              <Grid container spacing={0} className=''>
            <Grid className=' ' item md={4} xs={12}>
            <Card className='shadow' sx={{ minWidth: 270, margin: '10px' }}>
                    <CardContent className='d-flex align-items-center gap-2 '>
                      <Icon
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#FFF9E1',
                          color: '#FFD950',
                          padding: '8px',
                          borderRadius: '4px',
                          fontSize: '50px',
                        }}
                      >
                       <ContentPasteIcon />
                      </Icon>
                      <Box>
                        <Typography variant="p" component="div" sx={{fontSize:"12px"}}>
                        Total Samples Assigned
                        </Typography>
                        <Typography variant="h6" component="div">
                          {sampleAss}
                        </Typography>
                      </Box>
                    </CardContent>
        </Card>
            </Grid>

            <Grid className=' ' item md={4} xs={12}>
            <Card className='shadow' sx={{ minWidth: 270, margin: '10px' }}>

                    <CardContent className='d-flex align-items-center gap-2 '>
                      <Icon
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#FFDAE5',
                          color: '#FF2366',
                          padding: '8px',
                          borderRadius: '4px',
                          fontSize: '50px',
                        }}
                      >
                       <ContentPasteIcon />
                      </Icon>
                      <Box>
                        <Typography variant="p" component="div" sx={{fontSize:"12px"}}>
                        Total Reports Genrated
                        </Typography>
                        <Typography variant="h6" component="div">
                        {report}
                        </Typography>
                      </Box>
                    </CardContent>
          </Card>
            </Grid>
         </Grid>
             
              </Grid>
        </Grid>

    </Container>
   
    </div>
  )
}
export default Dashboard
