import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField } from '@mui/material'
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../hooks/useApi';
import apiClient, { setAuthToken } from '../api/apiClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from '../redux/counterSlice';
  import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { showModal } from '../redux/modalSlice';
   function Login () {
    const [data,setData]=useState({
      email:"",
      password:""
    })
const [load,setLoad]=useState(false)
const dispatch = useDispatch()
const {request}=useApi((data)=>apiClient.post("/auth/login",data))
const handleChange=(key,value)=>{
  setData({...data,[key]:value})
}
const navigate=useNavigate()
const {isLoggedIn,userType} = useSelector((state) => state.auth)
useEffect(() => {
if(isLoggedIn)
navigation(userType)
}, [])

// async function handleSubmit(e) {
//   e.preventDefault()
//   setLoad(true)
// const result=await request(data)
// //console.log(result);

// if(!result.ok) return toast.error(result.data)
// setAuthToken(result?.data?.token)
// const result1= await apiClient.get("/auth/user-info")
// setLoad(false)
//   //console.log(result1);
//   dispatch(login({token:result.data.token,userType:result1.data.role}))
//   navigation(result1.data.role)
// }

async function handleSubmit(e) {
  e.preventDefault();
  setLoad(true);

    const result = await request(data);
    //console.log(result);

    if (!result.ok) {
           dispatch(showModal({
        error:true,
        title: 'Login',
        message: result.data.message,
      }));
      setLoad(false); // Stop the loading state
      return;
    }

    setAuthToken(result?.data?.token);
    const result1 = await apiClient.get("/auth/user-info");
    //console.log(result1);
    dispatch(login({ token: result.data.token, userType: result1.data.role }));
    navigation(result1.data.role);

}


const navigation=(role)=>{
  if(role==="AD"||role==="SA")
  {
    navigate('/admin/dashboard');
  }else
  if(["PPTN","TN","EXP"].includes(role))
  {
    navigate('/tn/dashboard');
  }
}
if (isLoggedIn) {
  return null; 
}
  return (
    <>
    <div class="container py-2 gx-0  layout" >
    <Grid className=''  elevation={6} container spacing={0} >
        <Grid className=' py-5 shadow'  item xs={12} md={6}  
        sx={{paddingLeft: 0, height:'auto',
        borderRadius: {
          xs: '0',
          md: '40px 0 0 40px',
        },
        
        }}>
        
          {/* <div className='test' style={{ borderRadius: '0 40px 40px 0' }}>
          <img src={require("../assets/logo-new.png")} alt="" />
          </div> */}
          <div className=' px-2'>  
          {/* <h2 className='text-center text-white'>
          Welcome to a world where your <br />
          Pet Intolerances are taken <br />
          seriously.
          </h2> */}
          <p className='text-center px-5' style={{color:'#a69192'}}>
          “Our pet intolerance test is the road map to pet health.  We strive to make the best herbal products for pets and have exposure to as many pet parents as possible, so we can be the first choice rather than the last resort. “
          <p className=' pe-3'>
          - Debe Gwynn
          </p>
          </p>
          </div>
          
          <div className='d-flex justify-content-center '>

            <div style={{width:"30rem"}}>
              <img width={"100%"} src={require("../assets/pet_signin-removebg-preview.png")} alt="" />
            </div>
          </div>
        </Grid>
        
        <Grid component={Paper} sx={{
            borderRadius: {
              xs: '0',
              md: '0 40px 40px 0',
            },
            }} className='py-2 shadow' item xs={12} md={6}>
          <div className=' d-flex justify-content-center'>
        <div className=''>
          <div className='' style={{width:"13rem"}}>
          <img className='img-fluid' src={require("../assets/logo-new2.png")} alt="" />
          </div>
          
          </div>
          </div>
          <p className='text-center fs_24px'>
          {/* Glacier Peak Pet Intolerance Test */}
          Glacier Peak Biofeedback Center
          </p>
          <p className='text-center'>
          {/* Please fill in your name and email to get to test registration */}
          Please fill in your email and password to enter 
          </p>

        <form onSubmit={handleSubmit}>
        <Grid sx={{ p: 3 }}>
          <TextField fullWidth size="lg" id="email" label="Email" value={data.email} onChange={(e)=>handleChange("email", e.target.value)} variant="outlined" required />
        </Grid>
        <Grid sx={{ p: 3 }}>
          <TextField fullWidth type="password" id="password" label="Password" value={data.password} onChange={(e)=>handleChange("password", e.target.value)} variant="outlined" required />
        </Grid>

        <Grid className="px-4 text-end">
          {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" /> */}
            {!load?
          <Button className="btn_bg_secondery rounded-pill" sx={{ px: 6, py: 1, borderRadius: "50%" }} type="submit" variant="contained">
            Login
          </Button>:
          <Box sx={{ display: 'flex' ,justifyContent:"center"}}>
            <CircularProgress />
          </Box>}
        </Grid>
      </form>
        </Grid>
    </Grid>
    </div>
    <Footer/>
    </>
  )
}
export default Login;
