import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link, useNavigate } from 'react-router-dom';
import apiClient from '../api/apiClient';

import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { showModal } from '../redux/modalSlice';

function AddnewClient () {
  const navigate = useNavigate()
  const initilaState={
    fname:"",
    lname:"",
    address:"",
    state:"",
    zip:"",
    country:"",
    phone_no:"",
    email:"",
    barcode:"",
    pet:"",

  }
  const dispatch = useDispatch()
  const [client, setClient] = useState(initilaState);
  const [message, setmessage] = useState({text:"", color:''});
  const handelchange =(key, value)=>{
    setClient({...client, [key]: value})
  }
  const handelsubmit = async(e)=>{
    e.preventDefault();
    const result = await apiClient.post("/client/", client);
    //console.log(result);
    if (!result.ok)  return     dispatch(showModal({
      error:true,
      title: 'Client',
      message: result.data.message,
    }));
    navigate("/admin/client")
  }
function resetErrors(){
  setmessage({text:"", color:""})
}



  return (
    <div>
      <Container className='' maxWidth='lg'>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={10} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex align-items-center'>
            <div className='p-2 rounded-pill ' style={{backgroundColor:'#e8d9d5'}}>
              <Link to={'/admin/client'}>
            <ArrowBackIosIcon />
              </Link>
            </div>
              <h4 className='ps-1 mb-0 fw-bold'>
              Add New Clients:
              </h4>
            </div>
          </Grid>
    </Grid>
    <Grid md={10} xs={12}  spacing={2} component={Paper} elevation={5} sx={{marginTop:5, padding:5}}>
    <form onSubmit={handelsubmit}>
    <Grid md={10} xs={12}>
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="First Name" variant="outlined" value={client.fname} onChange={(e)=>handelchange("fname", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Last Name" variant="outlined" value={client.lname} onChange={(e)=>handelchange("lname", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Email" variant="outlined" value={client.email} onChange={(e)=>handelchange("email", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Contact" variant="outlined" value={client.phone_no} onChange={(e)=>handelchange("phone_no", e.target.value )} />
      </Grid>
    </Grid>

    <Typography sx={{py:2}} variant="h6" color="initial">Location</Typography>
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Address" variant="outlined" value={client.address} onChange={(e)=>handelchange("address", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Country" variant="outlined" value={client.state} onChange={(e)=>handelchange("country", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="State" variant="outlined" value={client.state} onChange={(e)=>handelchange("state", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Zip" variant="outlined" value={client.zip} onChange={(e)=>handelchange("zip", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="Barcode" variant="outlined" value={client.barcode} onChange={(e)=>handelchange("barcode", e.target.value)} />
      </Grid>
      <Grid item md={4} xs={12}>
      <TextField fullWidth   id="outlined-basic" label="pet" variant="outlined" value={client.pet} onChange={(e)=>handelchange("pet", e.target.value)} />
      </Grid>
    </Grid>
    
    </Grid>
    <Grid sx={{py:2}}>
      
        <Button className='btn_bg_secondery ' type='submit' variant='contained'>
          Save & Assign Barcodes
        </Button>
    
    </Grid>
    </form>
    </Grid>
    
      </Container>
    </div>
  )
}
export default AddnewClient
