import { createSlice } from '@reduxjs/toolkit';
import {  useNavigate } from "react-router-dom";
import colors, { layoutColors } from '../config/colors';

const initialState = {
  report: JSON.parse(localStorage.getItem('report')) || null,
};

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
  
    addReportData: (state, {payload}) => {
      let modifiedData={...state.report,...payload}
      localStorage.setItem('report',JSON.stringify(modifiedData))
      state.report = modifiedData;

    },
    removeReportData: (state) => {
        state.report = null;
        localStorage.removeItem('report')
      },
     
  }
});

// Action creators are generated for each case reducer function
export const {
  addReportData,
removeReportData,
} = reportSlice.actions;

export default reportSlice.reducer;
