import { Typography } from '@mui/material';
import React from 'react'
import { copyRightYear } from '../modules/helpers';
function Footer  ()  {
  return (
    <div className='py-3' style={{backgroundColor:"#3E4755"}}>
       <p className='text-center text-white mb-0' >
      &copy;{copyRightYear()} GLACIER PEAK HOLISTICS
          TEST REGISTRATION
        All Rights Reserved.
    </p>
    <Typography className='text-center text-white text-muted' variant="body2" color="inherit">
          All rights reserved  Maintained & Developed by 
          <span> </span>
        <a href="https://fabtechsol.com" target="_blank" rel="noopener noreferrer">
          fabtechsol
        </a>
      </Typography>
    </div>


  )
}
export default Footer;
