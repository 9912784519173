import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef } from "react";
import BackButton from "../components/mui/BackButton";
import { useState } from "react";
import apiClient from "../api/apiClient";
import { useParams } from "react-router-dom";

import { useReactToPrint } from "react-to-print";
import { copyRightYear } from "../modules/helpers";
export default    () => {
  const [gridData, setGridData] = useState();
  const {barcode}=useParams()




  const fetchData=async()=>{
    const result=await apiClient.get(`/report/${barcode}/`)
    if(!result.ok)return 
    //console.log(result.data,"SDDSADS");
  setGridData(result.data)
   }
useEffect(()=>{
fetchData()
},[])



const componentRef = useRef();

const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  return (
    <div className=""  style={{backgroundColor:"white"}}>
      {/* <Webnav/> */}
      <Box className=" py-5 d-flex justify-content-center">
        <Grid 
          className="d-flex align-items-center gap-2"
          container
          md="10"
          xs="12"
        >
          <BackButton to={"/"} />
          <span className=" mb-0 fw-bold">Generating Report:</span>
        </Grid>
      <button className="btn btn_bg text-white" onClick={handlePrint}>
        Download Report
      </button>
    
      </Box>
      <Box id="print_page" ref={componentRef}>
     
     



   

{/* CAtegories Items */}
    <Box className=" py-2 d-flex justify-content-center print-page-container print-container " style={{backgroundColor:"white"}} >

    <Grid className=" bg-white  py-5 w-100" container md={8} xs={12}>
      {gridData?.report_items?.map(({ category, items }, index) => (
        
    <Box className="w-100 py-0 page"  >
    {(index + 0) % 3 === 0 && (
      <Box className="page-header">
        <div className="">
          <Box  sx={{ maxWidth: '100%', width: '100%' }} >
            <p className="text-white px-3 py-3" style={{ backgroundColor: "#716879", fontSize: "2rem" }}>
              Glacier Peak Pet Intolerance Test
            </p>
          </Box>
          <div className="d-flex gap-3 align-items-center mb-1 px-2">
            <p className="fs_14px fw-bold mb-0">Item Sensitivity Rating</p>
            <p className="mb-0">
              <i className="bi bi-square-fill" style={{ color: "yellow" }}></i>
              <span className="ps-2">Moderate</span>
            </p>
            <p className="mb-0">
              <i className="bi bi-square-fill" style={{ color: "red" }}></i>
              <span className="ps-2">High</span>
            </p>
          </div>
        </div>
      </Box>
    )}
    <Box className="shadow p-1 py-3 text-center text-white" sx={{ backgroundColor: "#716879" }}>
      {/* Render additional text for a specific category */}
      {category === "Grains, Legumes (Beans), Nuts & Seeds" && (
        <div >
          <p className=" text-start">Include Oils</p>
          
        </div>
      )}
      {category === "MEAT / EGG" && (
        <div >
          <p className=" text-start">Include Oils</p>
          
        </div>
      )}
      {category === "Misc. ENVIRONMENTAL" && (
        <div >
          <p className=" text-start">Include Oils</p>
          
        </div>
      )}
      {category}
</Box>


    <div className="row px-2 gx-0" style={{ backgroundColor: "white", color:"#716879" }}>
      {items.map((item, key) => (
        <div key={key} className="col-3 d-flex">
          <div className="">
            {item.sensitivity === "MD" ? (
              <i className="bi bi-square-fill" style={{ color: "yellow",marginRight: "5px" }}></i>
            ) : (
              <i className="bi bi-square-fill" style={{ color: "red",marginRight: "5px" }}></i>
            )}
          </div>
          <div style={{color:"#716879"}} className="">
            <span>{item.name}</span>
          </div>
        </div>
      ))}
    </div>
   {(index + 1) % 3 === 0 && <>
   
   <Box
         className="page-footer"
      sx={{
        backgroundColor:"#F3E4D7",
        width: '100%',
        py: 1,
        px: 3,
        borderTop: '2px solid black',
      }}
    >
      <Box

        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="fs_10px mb-0">www.gphtest.com</p>
        <p className="fs_10px mb-0">© {copyRightYear()} FABTECHSOL. All rights reserved.</p>
      </Box>
   
    </Box>
    <div className="page-break"></div>
   </>
    }
  </Box>
))}

</Grid>
</Box>




</Box>
    


    </div>
  );
};
