import { Box, Button, Container, Grid, Paper } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import BarcodeList, { BarcodeOriginal } from '../components/Reactbarcode'
import CenterModal from '../components/Modal';
import { toast } from 'react-toastify';
import apiClient from '../api/apiClient';
import CircularProgress from '@mui/material/CircularProgress';
import Paginate from '../components/mui/Paginate';
import {Helmet} from "react-helmet";
import jsPDF from 'jspdf';  
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import html2pdf  from 'html2pdf.js';
import TabBars from '../components/mui/TabBars';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import SelectOption from '../components/mui/SelectOption';
import LoadingOverlay from '../components/mui/LoadingOverlay';
import { BarcodeFilterSidebar } from '../components/sidebar-filter/ProductFilterSidebar';
import { objectToQueryString } from '../modules/helpers';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BarcodeListArchive from '../components/BarcodeArchive';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
let limit=12
export default function   Barcode() {
  const [value, setValue] = useState(0);
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
<div className='d-flex justify-content-center'>

    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <Tabs value={value} onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        TabIndicatorProps={{ style: { backgroundColor: "#B89689" } }}

    aria-label="basic tabs example">
      <Tab label="Barcodes" {...a11yProps(0)} />
      <Tab label="Archives" {...a11yProps(1)} />
    </Tabs>
  </Box>
</div>
  <TabPanel value={value} index={0}>
    <BarcodeTab/>

   </TabPanel>
  <TabPanel value={value} index={1}>
    <ArchiveTab/>

   </TabPanel>
    </div>
  )
}

function ArchiveTab() {
    const [count, setCount] = useState(0);
    const [load, setLoad]= useState(false);
    const[barcodeValues,setBarcodeValues]=useState()
    const [barcodeType,setType]=useState("")
    const [showFilter, setShowFilter] = useState(false);
    const [date, setDate] = useState([]);
    const [reset, setReset] = useState(false);
    const intialFilters={
      barcode:"",
      activated:""
    }
    const [filters,setFilters]=useState(intialFilters)
    const onReset=()=>{
      setFilters(intialFilters)
     setReset(true)
    }
   const onFilterChange=(key,value)=>{
      setFilters({...filters,[key]:value})
    }
    useEffect(()=>{
      if(reset)
      fetchdata()
    },[reset])
    useEffect(() => {
      fetchdata()
    }, [barcodeType])

    const fetchdata=async(page=1)=>{
 
      if(page==1&&count)
      setCount(0)
      setLoad(true);
      const result=await apiClient.get(`/qr/?limit=${limit}&page=${page}&archive=true&key=${barcodeType}&${objectToQueryString(filters)}`);
      setLoad(false);
      if(!result.ok) return toast.error("Error");
      if (!count||page==1) setCount(result.data.count);
      //console.log(result.data,"lllllll");
       setBarcodeValues(result.data.results);
    }
 const handlePageChange = (event, value) => {
      fetchdata(value);
    };

    const baseUrl = "www.gphtest.com";

    return (
      <div >
        <Helmet>
          <title>
            Barcode
          </title>
        </Helmet>
        <Container className='' maxWidth='lg'>
        <BarcodeFilterSidebar
        openFilter={showFilter}
        onOpenFilter={() => setShowFilter(true)}
        onCloseFilter={() => setShowFilter(false)}
        handleChange={onFilterChange}
        values={filters}
        onReset={onReset}
        fetchData={fetchdata}
      />
      {load?(
        <Box display="flex" justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
      ): 
        (<div className='d-flex justify-content-center '>
          
       <Grid  md={9} xs={12}  container  className='shadow' sx={{marginTop:5, borderRadius:10}}  >
       <Grid className='p-3 row justify-content-end' item xs={12}>

<div className='col-4'>
<SelectOption style={{width:"100%"}} size="small"  
data={
[{value:"!pp",label:"Normal"},
{value:"pp",label:"PP Technician"},
{value:"exp",label:"EXP Technician"}
]}  id="outlined-basic" value={barcodeType} 
onChange={(e) => {
  setType(e.target.value);
  console.log(e.target.value);
}}
 label="Barcode Type" variant="outlined" />
</div>

 

      </Grid>
  
        {barcodeValues?.map(( value)=>(
      
     
         <Grid className='' item md={4} sm={6} xs={12} spacing={5} sx={{ marginTop: 3, padding: 2 }}>
           <BarcodeListArchive  url={baseUrl} value={value} ShowiIcon={true}  />
         </Grid>
       
         ))}
     
            
        </Grid>
      </div>)}
    <div className={`${load&&"d-none "}   d-flex justify-content-center `} >
   <Paginate
     className="btn_bg" color="primary" style={{ color: "white",  }} count={count} limit={limit} onChange={handlePageChange}/>
    </div>  
  
        </Container>
      </div>
    )
  }

function BarcodeTab  ()  {
  const [count, setCount] = useState(0);
  const [load, setLoad]= useState(false);
  const [modalShow, setModalShow] = useState(false);  
  const[barcodeValues,setBarcodeValues]=useState()
  const[DBarcodes,setDBarcodes]=useState()
  const [barcodeType,setType]=useState("")
  useEffect(() => {
    fetchdata()
  }, [barcodeType])

 
  const [downloadLoad,setDownloadLoad]=useState(false)
  const handleDownload = async () => {
    const element = document.getElementById("barcode-list-container");
    const options = {
        // margin:       5,
        html2canvas: {
          dpi: 201,
          scale:4,
          letterRendering: true,
          useCORS: true
        },
        filename: "barcodes.pdf", // Specify the filename for the downloaded PDF
        jsPDF: { unit: "mm", format: [50.8, 25.4], orientation: "landscape" } // Set the PDF format and orientation
       };
    
    html2pdf().set(options).from(element).save();
    setDownload(false)
    const barcodes=DBarcodes.map(x=>x.key)
   await archiveAll(barcodes)
    setDownloadLoad(false)
  };
  



  const fetchdata=async(page=1)=>{
    if(page==1)
    fetchDataD()
    if(page==1&&count)
    setCount(0)
    setLoad(true);
    const result=await apiClient.get(`/qr/?limit=${limit}&page=${page}&archive=false&activated=false&key=${barcodeType}`);
    setLoad(false);
    if(!result.ok) return toast.error("Error");
    if (!count||page==1) setCount(result.data.count);
    //console.log(result.data,"lllllll");
     setBarcodeValues(result.data.results);
  }
  const fetchDataD=async()=>{
  
    setLoad(true);
    const result=await apiClient.get(`/qr/?limit=100&archive=false&activated=false&key=${barcodeType}`);
    setLoad(false);
    if(!result.ok) return toast.error("Error");
     setDBarcodes(result.data.results);
  }
  const archiveAll=async(barcodes)=>{
  
    const result=await apiClient.put(`/qr/archive-all`,{barcodes});
    if(!result.ok) return toast.error("Failed to archive barcodes");
    fetchdata()
  }
//console.log(count,"cccccvvcvcvcvcvccv")
  const handlePageChange = (event, value) => {
    setDownload(false);
    fetchdata(value);
  };
  //console.log(barcodeValues,"hjhjjh")
 
  // const barcodeValues = ['000000001', '000000002', '000000003','000000004','000000005','000000006',];
  const baseUrl = "www.gphtest.com";
const [download,setDownload]=useState()
  useEffect(()=>{
    if(download)
    handleDownload()
  },[download])
 
  return (
    <div >
      <Helmet>
        <title>
          Barcode
        </title>
      </Helmet>
      <Container className='' maxWidth='lg'>
      <LoadingOverlay open={downloadLoad}/>
      <Grid container className='d-flex justify-content-center ' spacing={2}>
          <Grid item md={8} sx={{backgroundColor:""}}  xs={12}>
          <div className='d-flex justify-content-between align-items-center flex-column flex-sm-row'>
              <h3 className='mb-0'>
              {/* Barcodes: */}
              </h3>
              
              <Button sx={{borderRadius:20, fontSize:10}} onClick={()=>setModalShow(true)
              } className='btn_bg_secondery px-2 ' variant='contained'>
                Generate Barcode
              </Button>
              <CenterModal
             fetchData={fetchdata}
              show={modalShow}
              onHide={()=>setModalShow(false)}
              />
            </div>
          </Grid>
    </Grid>
   
    {load?(
      <Box display="flex" justifyContent="center" mt={5}>
      <CircularProgress />
    </Box>
    ): 
      (<div className='d-flex justify-content-center '>
     <Grid  md={9} xs={12}  container  className='shadow' sx={{marginTop:5, borderRadius:10}}  >
      <Grid className='p-3 row justify-content-between' item xs={12}>
<div className='col-6'>

    <Button 
    disabled={downloadLoad}
      className="btn btn_bg_secondery" style={{whiteSpace:"nowrap"}} onClick={()=>{
      
          setDownloadLoad(true);
          // Wait a short moment to allow the component to re-render with the loading spinner
          setTimeout(() => {
            setDownload(true);
          }, 50);
   
        }}>
       Download Barcodes
      </Button>
</div>
<div className=' col-4'>

<SelectOption style={{width:"100%"}} size="small"  
data={
[{value:"!pp",label:"Normal"},
{value:"pp",label:"PP Barcodes"},
{value:"exp",label:"EXP Barcodes"}
]}
  id="outlined-basic" value={barcodeType} onChange={(e)=>setType(e.target.value)} label="Barcode Type" variant="outlined" />
</div>

 

      </Grid>

      {barcodeValues?.map(( value)=>(
    
   
       <Grid className='' item md={4} sm={6} xs={12} spacing={5} sx={{ marginTop: 3, padding: 2 }}>
         <BarcodeList url={baseUrl} value={value} ShowiIcon={true}  />
       </Grid>
     
       ))}
   
          
      </Grid>
    </div>)}
  <div className={`${load&&"d-none test"}  d-flex justify-content-center `}>

<Paginate style={{ color: "white"}} count={count} limit={limit}  onChange={handlePageChange}/>
  </div>  

      </Container>
{download&&<PrintMain baseUrl={baseUrl} barcodeValues={DBarcodes}/>}
    </div>
  )
}


const PrintMain = ({baseUrl,barcodeValues}) => {
  const updatedData= barcodeValues.reduce((acc, item) => {
    const copies = [1, 2, 3].map(() => ({ ...item }));
    return [...acc, ...copies];
  }, []);

    return (
    
     
       <div className='d-flex justify-content-center' >

       <div  id='barcode-list-container'     >
   

   {updatedData?.map(( {key},index)=>(
    

       <div key={index}     >
         <BarcodeOriginal  url={baseUrl} value={key} />
       </div>
 
      
 
       )) }
    </div>
       </div>
   
     
    );
};
