import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import reportSlice from './reportSlice';
import modalSlice from './modalSlice';

export const store = configureStore({
	reducer: {
		auth: counterReducer,
		report: reportSlice,
		modal: modalSlice,
	},
});
